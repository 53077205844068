import {create} from 'zustand';
import {useLocalStore} from "./localState";

export const useLogerStore = create((set, get) => {

    const {setAuthenticate} = useLocalStore.getState()

    function setTime(dateTime, val, setDateFrom, setDateTo, setTimeTo, setTimeFrom) {

        for (const key in dateTime) {

            if (val === "HOUR") {
                setDateFrom(new Date());
                setDateTo(new Date());
                setTimeTo(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes()));
                setTimeFrom((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours() - 1, new Date().getMinutes())));
            } else if (val === "DAY") {
                setDateFrom((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1, new Date().getHours(), new Date().getMinutes())));
                setDateTo((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())));
                setTimeTo(new Date());
                setTimeFrom(new Date());
            } else if (val === "WEEK") {
                setDateFrom((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, new Date().getHours(), new Date().getMinutes())));
                setDateTo((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())));
                setTimeTo("");
                setTimeFrom("");
            } else if (val === "MONTH") {
                setDateFrom((new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), new Date().getHours(), new Date().getMinutes())));
                setDateTo((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())));
                setTimeTo("");
                setTimeFrom("");
            } else if (val === "YEAR") {
                setDateFrom((new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())));
                setDateTo((new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes())));
            } else {
                setDateFrom('');
                setDateTo('');
                setTimeTo('');
                setTimeFrom('');
            }

            if (val === key) {
                dateTime[key] = !dateTime[key]

            } else {
                dateTime[key] = false
            }
        }
        return dateTime
    }

    function checkedChecker(idx, checked) {
        var indx = checked.indexOf(idx);
        if (indx !== -1) {
            return [...checked.slice(0, indx), ...checked.slice(indx + 1)]
        }
        return [...checked, idx]
    }

    return {
        dateTime: {
            HOUR: false,
            DAY: false,
            WEEK: false,
            MONTH: false,
            YEAR: false,
            ALL: true,
        },
        dateTo: '',
        timeTo: '',
        dateFrom: '',
        timeFrom: '',

        sort: false,
        checked: [],

        logID: '',
        domains: '',
        cookies: '',
        wallets: '',
        buildTags: '',
        country: '',
        delCountry: '',
        status: '',
        ip: '',
        comment: '',

        setDomains: (value) => set(() => ({domains: value})),
        setWallets: (value) => set(() => ({wallets: value})),
        setCountry: (value) => set(() => ({country: value})),
        setDelCountry: (value) => set(() => ({delCountry: value})),
        setBuildTags: (value) => set(() => ({buildTags: value})),
        setLogID: (value) => set(() => ({logID: value})),
        setStatus: (value) => set(() => ({status: value})),
        setIP: (value) => set(() => ({ip: value})),
        setComment: (value) => set(() => ({comment: value})),

        setDateTo: (value) => set(() => ({dateTo: value})),
        setTimeTo: (value) => set(() => ({timeTo: value})),
        setDateFrom: (value) => set(() => ({dateFrom: value})),
        setTimeFrom: (value) => set(() => ({timeFrom: value})),

        setCheckedAll: (bool) => set((state) => ({checked: bool ? [...state.data.map(d => d.LogID)] : []})),
        setChecked: (idx) => set(() => ({checked: checkedChecker(idx, get().checked)})),

        offsetClear: () => set(() => ({offset: 0})),
        fetchLoad: () => set({loading: true}),
        fetchData: async () => {
            let $url = `${process.env.REACT_APP_URL}/authed/sort?offset=${get().offset}`;

            if (get().logID !== '') {
                $url += `&log=${get().logID}`
            }
            if (get().wallets.length !== 0) {
                $url += `&wallets=${get().wallets}`
            }
            if (get().domains !== '') {
                $url += `&domains=${get().domains}`
            }
            if (get().buildTags !== '') {
                $url += `&build=${get().buildTags}`
            }
            if (get().country.length !== 0) {
                $url += `&country=${get().country}`
            }
            if (get().delCountry.length !== 0) {
                $url += `&delcountry=${get().delCountry}`
            }
           
            if (get().dateFrom !== '') {
                const h = (get().timeFrom && get().timeFrom.getHours()) || 0
                const m = (get().timeFrom && get().timeFrom.getMinutes()) || 0
                var dt = get().dateFrom.toISOString()

                var t = `T${((h > 9) ? h : ("0"+ h)) +":"+ ((m > 9) ? m : "0"+m)}`
                dt = dt.split("T")[0] + t + ":00.000Z"
                $url += `&datefrom=${dt}`
            }
            if (get().dateTo !== '') {
                const h = (get().timeTo && get().timeTo.getHours()) || 0
                const m = (get().timeTo && get().timeTo.getMinutes()) || 0
                var dt = get().dateTo.toISOString()

                var t = `T${((h > 9) ? h : ("0"+ h)) +":"+ ((m > 9) ? m : "0"+m)}`
                dt = dt.split("T")[0] + t + ":00.000Z"
                $url += `&dateto=${dt}`
            }
            if (get().status !== '') {
                $url += `&status=${get().status}`
            }
            if (get().ip !== '') {
                $url += `&ip=${get().ip}`
            }
            if (get().comment !== '') {
                $url += `&comment=${get().comment}`
            }
            set({loading: true})
            const response = await fetch($url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": useLocalStore.getState().token,
                },
            }).then(res => {
                if (res.status === 403) {
                    setAuthenticate(false);
                }
                if (res.status > 306) {
                    set({loading: false});
                    set({fetchError: true});
                }
                return res.json()
            }) .then(res => {
                set({offset: get().offset + get().data.length});
                set({loading: false});
                set({fetchError: false});
                set({data: [...get().data, ...res]});
                set({viewOffset: get().data.length});
            }).catch(err => {
                set({loading: false});
                set({fetchError: true});
            })
        },

        clearData: () => set(() => ({data: []})),
        clear: async () => set(() => ({
            domains: "",
            wallets: "",
            buildTags: "",
            country: "",
            delCountry: "",
            dateTo: "",
            dateFrom: "",
            ip: "",
            comment: "",
            tag: "",
            tags: "",
            logID: "",
            status: "",
            dateTime: {
                HOUR: false,
                DAY: false,
                WEEK: false,
                MONTH: false,
                YEAR: false,
                ALL: true,
            },
        })),

        setDateTime: (val) => set((state) => ({dateTime: setTime(state.dateTime, val, get().setDateFrom, get().setDateTo, get().setTimeTo, get().setTimeFrom)})),

        data: [],
        offset: 0,
        viewOffset: 0,
        fetchError: false,
        loading: false,

        statistic: {
            Account: null,
            Stats: null,
        },

        setFilterData: (array) => {
            const s = new Set(array);
            set({data: get().data.filter(d => !s.has(d.LogID))})
        },
        setStatistic: (value) => set({statistic: value}),
    }
})