import React from "react";
import { useLocalStore } from "../../store/localState";

export const Container = ({children, styles}) => {

    const theme = useLocalStore(state => state.theme)

    return <div className={`${theme ? "bg-B2" : "bg-B1"} ${styles} py-[100px] min-h-screen h-full`}>
        {children}
    </div>

}