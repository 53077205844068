import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocalStore } from "../../store/localState";
import { useModalStore } from "../../store/modalState";

import { Response } from "../../components/response-message";
import { Container } from "../../components/Page";

export const Admin = () => {
    const { handleAdminUser, $adminAdd, $adminUpdate, $adminWarning, $current } = useModalStore((state) => state);
    const { lang, theme, setAuthenticate, token } = useLocalStore((state) => state);

    const [value, setValue] = useState({
        login: "",
        password: "",
        subtime: null,
        balance: null,
    });
    const [users, setUsers] = useState([{id: 1, login: "test", password: "lorem5", subtime: "01/01/2025", balance: 0}]);
////{id: 1, login: "test", password: "lorem5", subtime: "01/01/2025", balance: 0}
    const [error, setError] = useState(null)

    async function getUsers() {
        const response = await fetch(`${process.env.REACT_APP_URL}/admin/users/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        }).then(res => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            return res.json()
        }).then((res) => setUsers(res)).catch(err => console.error(err))
    }

    useEffect(() => {
        getUsers()
    }, []);

    const edit = async (e) => {
        e.preventDefault()
        var balance = users[$current - 1].balance.replace(/[^+\d]/g, '')
        const response = await fetch(`${process.env.REACT_APP_URL}/admin/edit_user/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({
                id: users[$current - 1].id,
                login: users[$current - 1].login,
                subtime: users[$current - 1].subtime,
                password: users[$current - 1].password,
                balance: Number(balance)
            })
        }).then(res => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            if (res.status > 306) {
                setError(true)
            } else {
                setError(false)
            }
        }).catch(error => {
            setError(true)
            console.error(error)
        })
    }
    const add = async (e) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_URL}/admin/add_user/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({
                login: value.login,
                password: value.password,
                subtime: value.subtime
            })
        }).then(res => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            if (res.status > 306) {
                setError(true)
            } else {
                setError(false)
            }
        }).catch(error => {
            setError(true)
            console.error(error)
        })
    }

    const del = async (e, u) => {
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_URL}/admin/delete_user/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ login: u.login })
        }).then(res => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            if (res.status < 306) {
                setUsers([...users.slice(0, $current - 1), ...users.slice($current)]);
                handleAdminUser("delete", -1)
            }
        }).catch((err) => console.error(err))
    }

    return (
        <Container>
            <div className="w-4/6 mx-auto mt-16 min-w-[500px]">
                <div className={`flex justify-start items-center`}>
                    <div className={`text-xl ${theme ? "text-S2" : "text-S1"} mr-12`}>
                        PROFIT: {users.length * 3000}
                    </div>
                    <button
                        onClick={() => handleAdminUser('add')}
                        className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} px-6 py-2 bg-transparent rounded-2xl mr-3`}>{lang == 'ru' ? "ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ" : "ADD USER"}</button>
                </div>
                {
                    $adminAdd &&
                    <div
                        className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 px-5 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
                        <div
                            className={`w-2/6  ${theme ? "bg-B2" : "bg-B1"} pb-5  rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                            <div className="flex items-center mx-auto w-full px-10 mt-5 justify-between flex-col">
                                <label
                                    className={`${theme ? "text-black" : "text-white"} text-3xl text-start mb-2`}>{lang === 'ru' ? "Добавить пользователя" : "ADD USER"}</label>
                                <label className="text-[#83858C] w-full mt-4 mx-1">{lang === 'ru' ? "Логин" : "Login"} <br />
                                    <input
                                        onChange={(e) => setValue({ ...value, login: e.target.value })}
                                        placeholder={`${lang === 'ru' ? "Логин" : "Login"}`}
                                        className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        type="text"
                                    />
                                </label>
                                <label className="text-[#83858C] w-full my-1">{lang === 'ru' ? "Пароль" : "Password"} <br />
                                    <input
                                        value={value.password}
                                        onChange={(e) => setValue({ ...value, password: e.target.value })}
                                        className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        placeholder={lang === 'ru' ? "Пароль" : "Password"} />
                                </label>
                                <label className="w-full text-[#83858C] my-1">Subtime <br />
                                    <DatePicker
                                        dateFormat={"yyyy/MM/dd"}
                                        placeholderText="2024/11/01"
                                        className={`admin w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        selected={value.subtime}
                                        onChange={(date) => {
                                            setValue({ ...value, subtime: date })
                                        }} />
                                </label>
                                <button
                                    onClick={(e) => add(e)}
                                    className={`w-full mt-5 rounded-2xl text-white py-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>{lang == 'ru' ? "ВВОД" : "ADD"}</button>
                                <button
                                    onClick={(e) => {
                                        setError(null)
                                        handleAdminUser('add')
                                    }}
                                    className={`w-full rounded-2xl text-white py-2 mt-2  bg-AD font-semibold`}>{lang === 'ru' ? "ЗАКРЫТЬ" : "CANCEL"}</button>
                                <div>
                                    <Response error={error} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {$adminUpdate &&
                    <div
                        className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
                        <div
                            className={`w-2/6 ${theme ? "bg-B2" : "bg-B1"} pb-5 rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                            <div className="flex items-center mx-auto w-full  mt-5 justify-between flex-col px-10">
                                <label
                                    className={`${theme ? "text-black" : "text-white"} text-3xl text-start mb-2`}>{lang === 'ru' ? "Обновить пользователя" : "UPDATE USER"}</label>
                                <label className="text-[#83858C] w-full mt-4 mx-1">{lang === 'ru' ? "Логин" : "Login"} <br />
                                    <input
                                        value={users[$current - 1].login}
                                        onChange={(e) => setUsers([...users.slice(0, $current - 1), {
                                            ...users[$current - 1],
                                            login: e.target.value
                                        }, ...users.slice($current)])}
                                        placeholder={`${lang === 'ru' ? "Логин" : "Login"}`}
                                        className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        type="text"
                                    />
                                </label>
                                <label className="text-[#83858C] w-full my-1">{lang === 'ru' ? "Пароль" : "Password"} <br />
                                    <input
                                        value={users[$current - 1].password}
                                        onChange={(e) => setUsers([...users.slice(0, $current - 1), {
                                            ...users[$current - 1],
                                            password: e.target.value
                                        }, ...users.slice($current)])}
                                        className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        placeholder={lang === 'ru' ? "Пароль" : "Password"} />
                                </label>
                                <label className="text-[#83858C] w-full my-1">{lang === 'ru' ? "Баланс" : "Balance"} <br />
                                    <input
                                        value={users[$current - 1].balance}
                                        onChange={(e) => setUsers([...users.slice(0, $current - 1), {
                                            ...users[$current - 1],
                                            balance: e.target.value
                                        }, ...users.slice($current)])}
                                        className={`max-w-[210px] w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        placeholder={lang === 'ru' ? "Баланс" : "Balance"} />
                                </label>
                                <label className="text-[#83858C] w-full my-1">Subtime <br />
                                    <DatePicker
                                        dateFormat={"yyyy/MM/dd"}
                                        placeholderText="2024/11/01"
                                        className={`admin w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-sm" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                                        selected={users[$current - 1].subtime}
                                        onChange={(date) => setUsers([...users.slice(0, $current - 1), {
                                            ...users[$current - 1],
                                            subtime: date.toISOString()
                                        }, ...users.slice($current)])}
                                    />
                                </label>
                                <button
                                    onClick={(e) => edit(e)}
                                    className={`w-full mt-5 rounded-2xl text-white py-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>{lang === 'ru' ? "ВВОД" : "EDIT"}</button>
                                <button
                                    onClick={(e) => {
                                        setError(null)
                                        handleAdminUser('edit')
                                    }}
                                    className={`w-full rounded-2xl text-white py-2 mt-2  bg-AD font-semibold`}>{lang === 'ru' ? "ЗАКРЫТЬ" : "CANCEL"}</button>
                                <div>
                                    <Response error={error} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={`flex mt-10 justify-between ${theme ? 'text-black' : "text-white"} border ${theme ? "border-black" : "border-white"}  `}>
                    <div className={`border-r ${theme ? "border-black" : "border-white"} w-full text-center`}>ID</div>
                    <div className={`border-r ${theme ? "border-black" : "border-white"} w-full text-center`}>USERNAME</div>
                    <div className={`border-r ${theme ? "border-black" : "border-white"} w-full text-center`}>PASSWORD</div>
                    <div className={`border-r ${theme ? "border-black" : "border-white"} w-full text-center`}>SUBTIME</div>
                    <div className={`border-r ${theme ? "border-black" : "border-white"} w-full text-center`}>BALANCE</div>
                    <div className={` w-full text-center`}>ACTION</div>
                </div>


                {$adminWarning &&
                    <div
                        className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
                        <div
                            className={`w-86 ${theme ? "bg-B2" : "bg-B1"} px-10 py-5 rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                            <span className={`text-red-800 flex items-center`}>
                                <svg fill="currentColor" className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                DELETE USER {users[$current - 1].id}:{users[$current - 1].login}?
                            </span>
                            <div className={`flex gap-10 mt-4 justify-center items-center ${theme ? "text-white" : ""}`}>
                                <button
                                    onClick={(e) => del(e, users[$current - 1])}
                                    className={`px-4 py-2 border-2 text-red-800 border-red-800`}>
                                    YES
                                </button>
                                <button
                                    onClick={(e) => handleAdminUser('delete', -1)}
                                    className={`px-4 py-2 border-2 ${theme ? "text-S2" : "text-S1"} ${theme ? "border-S2" : "border-S1"}`}>
                                    NO
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {users.map((u, idx) =>
                    <div key={u.id}
                        className={`flex mt-1 justify-between ${theme ? 'text-black' : "text-white"} border ${theme ? "border-B1" : "border-B2"}   `}>
                        <div
                            className={`border-r ${theme ? "border-B1" : "border-B2"}  w-full flex items-center justify-center text-center break-all `}>{u.id}</div>
                        <div
                            className={`border-r ${theme ? "border-B1" : "border-B2"}  w-full flex items-center justify-center text-center break-all `}>{u.login}</div>
                        <div
                            className={`border-r ${theme ? "border-B1" : "border-B2"}  w-full flex items-center justify-center text-center break-all `}>{u.password}</div>
                        <div
                            className={`border-r ${theme ? "border-B1" : "border-B2"}  w-full flex items-center justify-center text-center break-all `}>{u.subtime}</div>
                             <div
                            className={`border-r  w-full flex items-center justify-center text-center break-all `}>{u.balance}</div>
                        <div className={`w-full`}>
                            <div
                                onClick={() => handleAdminUser('edit', idx + 1)}
                                className={`flex h-1/2 justify-center border-2 ${theme ? "text-S2" : "text-S1"} ${theme ? "border-S2" : "border-S1"} items-center w-full text-center cursor-context-menu`}>EDIT
                            </div>
                            <div
                                onClick={() => handleAdminUser('delete', idx + 1)}
                                className={`flex h-1/2 text-AD justify-center border-2 border-AD items-center w-full text-center cursor-pointer`}>DELETE
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Container>
    )
}