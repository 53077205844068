import React from 'react';

export const CryptocomSVG = ({theme}) => {

    return <svg height="20" viewBox="0 0 199 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M51.337 28.9604C46.9876 28.9604 43.7734 25.5534 43.7734 21.3687C43.7734 17.1839 46.9876 13.6934 51.364 13.6934C54.1358 13.6934 55.8534 14.7192 57.2111 16.2157L55.1334 18.4592C54.1076 17.3792 53.0276 16.631 51.337 16.631C48.8981 16.631 47.1252 18.7369 47.1252 21.3134C47.1252 23.9451 48.9264 26.051 51.5028 26.051C53.0828 26.051 54.2464 25.3028 55.2993 24.2228L57.3217 26.2169C55.8817 27.8239 54.1911 28.9604 51.337 28.9604"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M63.6838 28.9603H60.332V13.7214H63.6838V17.2956C64.5979 15.1061 66.2885 13.6097 68.865 13.7214V17.2685H68.6709C65.7344 17.2685 63.6838 19.1803 63.6838 23.0591V28.9603Z"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M86.4142 13.6934L79.006 33.0628H75.6071L77.226 28.9651L70.8789 13.6934H74.453L78.7836 24.9734L82.9413 13.6934H86.4142Z"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M96.306 16.6028C94.0342 16.6028 92.0389 18.4592 92.0389 21.3134C92.0389 24.1675 94.0342 26.0228 96.306 26.0228C98.606 26.0228 100.49 24.2228 100.49 21.3134C100.49 18.431 98.5778 16.6028 96.306 16.6028M97.0813 28.9322C94.6719 28.9322 93.1472 27.7134 92.1213 26.3557V33.0616H88.7695V13.6934H92.1213V16.4357C93.2025 14.9122 94.7272 13.6934 97.0813 13.6934C100.545 13.6934 103.898 16.4357 103.898 21.3134C103.898 26.1898 100.573 28.9322 97.0813 28.9322"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M111.246 16.8803V24.0544C111.246 25.3556 111.907 25.8815 113.046 25.8815H114.361V28.8756H112.068C109.628 28.8685 107.894 27.7838 107.894 24.5803V16.8803H106.033V14.0015H107.894V9.98267H111.246V14.0015H114.361V16.8803H111.246Z"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M124.966 16.6305C122.278 16.6305 120.56 18.7364 120.56 21.3129C120.56 23.9176 122.416 26.0505 125.021 26.0505C127.735 26.0505 129.454 23.9458 129.454 21.3682C129.454 18.7635 127.598 16.6305 124.966 16.6305M124.966 28.9599C120.505 28.9599 117.207 25.5529 117.207 21.3682C117.207 17.1564 120.532 13.6929 125.021 13.6929C129.509 13.6929 132.807 17.1011 132.807 21.3129C132.807 25.4976 129.481 28.9599 124.966 28.9599"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M138.943 25.8853V27.5747C138.943 27.6465 138.907 27.7112 138.845 27.7441L137.383 28.5888C137.321 28.6241 137.246 28.6241 137.189 28.5888L135.723 27.7441C135.664 27.7112 135.625 27.6465 135.625 27.5747V25.8853C135.625 25.8171 135.664 25.7524 135.723 25.7159L137.189 24.8712C137.246 24.8359 137.321 24.8359 137.383 24.8712L138.845 25.7159C138.907 25.7524 138.943 25.8171 138.943 25.8853"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M164.858 16.6305C162.17 16.6305 160.453 18.7364 160.453 21.3129C160.453 23.9176 162.309 26.0505 164.914 26.0505C167.628 26.0505 169.345 23.9458 169.345 21.3682C169.345 18.7635 167.49 16.6305 164.858 16.6305M164.858 28.9599C160.397 28.9599 157.1 25.5529 157.1 21.3682C157.1 17.1564 160.424 13.6929 164.914 13.6929C169.402 13.6929 172.7 17.1011 172.7 21.3129C172.7 25.4976 169.374 28.9599 164.858 28.9599"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M149.15 28.9432C144.801 28.8597 141.653 25.3903 141.734 21.2068C141.814 17.0244 145.094 13.5962 149.47 13.6797C152.241 13.7327 153.939 14.7903 155.268 16.3127L153.147 18.5162C152.143 17.4162 151.077 16.648 149.387 16.615C146.949 16.568 145.135 18.6397 145.086 21.2162C145.035 23.848 146.796 25.988 149.372 26.0374C150.952 26.068 152.129 25.3421 153.202 24.2821L155.187 26.315C153.716 27.8938 152.005 28.9974 149.15 28.9432"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M192.627 13.6934C195.814 13.6934 197.753 15.7157 197.753 19.2898V28.9604H194.402V20.3157C194.402 17.9887 193.32 16.7416 191.437 16.7416C189.609 16.7416 188.305 18.0157 188.305 20.371V28.9604H184.953V20.2875C184.953 18.0157 183.844 16.7416 181.987 16.7416C180.132 16.7416 178.857 18.1275 178.857 20.3992V28.9604H175.504V13.6934H178.857V16.2157C179.799 14.9134 181.074 13.6934 183.318 13.6934C185.424 13.6934 186.892 14.7192 187.667 16.2698C188.86 14.7192 190.411 13.6934 192.627 13.6934"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 10.7003V29.747L16.4106 39.2717L32.8176 29.747V10.7003L16.4106 1.17651L0 10.7003ZM1.44442 28.9119V11.539L16.4106 2.8484L31.3777 11.539V28.9119L16.4106 37.5989L1.44442 28.9119Z"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M22.8405 9.39941H9.94599L8.45117 16.0083H24.3947L22.8405 9.39941Z"  fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.2336 25.1033V20.7119L8.41426 18.271L4.0918 21.5008L9.98377 31.8008H12.3371L15.1207 29.1962V27.8871L12.2336 25.1033Z"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.5978 17.0056H12.248L13.6547 20.6977L13.2263 24.8349H16.4085L19.6213 24.8204L19.2209 20.6977L20.5978 17.0056Z"
              fill={theme ? "black" : "white"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M24.4173 18.241L20.642 20.7117V25.1031L17.7559 27.8869V29.196L20.5385 31.7717H22.8622L28.7254 21.5006L24.4173 18.241Z"
              fill={theme ? "black" : "white"} />
    </svg>

}