import React from 'react';

export const ShowPassSVG = React.memo(({showPass, onClick, className}) => {


    return <>
        {showPass ?
            <svg
                onClick={onClick}
                className={className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 5C6.55576 5 3.53109 9.23425 2.45554 11.1164C2.23488 11.5025 2.12456 11.6956 2.1367 11.9836C2.14885 12.2716 2.27857 12.4598 2.53799 12.8362C3.8182 14.6935 7.29389 19 12 19C16.7061 19 20.1818 14.6935 21.462 12.8362C21.7214 12.4598 21.8511 12.2716 21.8633 11.9836C21.8754 11.6956 21.7651 11.5025 21.5445 11.1164C20.4689 9.23425 17.4442 5 12 5Z"
                    stroke="white" strokeWidth="2"/>
                <circle cx="12" cy="12" r="4" fill="white"/>
            </svg>
            :
            <svg
                onClick={onClick}
                className={className} width="24" height="24" viewBox="0 0 20 14" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_35_504)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M0.979299 5.27094C2.3646 3.19808 5.26857 0 10 0C14.7315 0 17.6354 3.19808 19.0207 5.27094C19.4856 5.96655 19.718 6.31435 19.6969 6.95691C19.6757 7.59948 19.4089 7.94688 18.8753 8.64168C17.2861 10.7107 14.113 14 10 14C5.88705 14 2.7139 10.7107 1.12477 8.64168C0.591123 7.94688 0.3243 7.59948 0.303144 6.95691C0.281988 6.31435 0.514425 5.96655 0.979299 5.27094ZM10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79087 3 6.00001 4.79086 6.00001 7C6.00001 9.20914 7.79087 11 10 11Z"
                          fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_35_504">
                        <rect width="20" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        }
    </>
})