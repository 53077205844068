import React, { useCallback } from "react";

import { RouteLink } from "./link";

import { ConverterSVG } from "../SVG/converterSVG";
import { LangSVG } from "../SVG/langSVG";
import { BurgerSVG } from "../SVG/burgerSVG";
import { UserSVG } from "../SVG/userSVG";
import { DashboardSVG } from "../SVG/dashboard";
import { GoogleCookiesSVG } from "../SVG/googleCookiesSVG";
import { SettingSVG } from "../SVG/settingSVG";
import { LogSVG } from "../SVG/logSVG";
import { BuilderSVG } from "../SVG/builderSVG";

import { useModalStore } from "../../store/modalState";
import { useLocalStore } from "../../store/localState";
import { useLogerStore } from "../../store/logerState";
import { AccountSVG } from "../SVG/accountSVG";
import { DataSVG } from "../SVG/dataSVG";


export const Menu = React.memo(() => {
    const { statistic } = useLogerStore(state => state)
    const { $menu, handleMenu, $currentPage } = useModalStore((state) => state);
    const { lang, setLang, theme, setTheme } = useLocalStore((state) => state);

    const changeTheme = useCallback(() => {
        setTheme()
    }, [theme])

    const handler = useCallback(() => {
        handleMenu()
    }, [$menu])



    return (
        <>
            <div className="flex justify-between">
                <div>
                    <div className="mx-6 my-4 flex items-center">
                        <div className="max-[640px]:absolute max-[640px]:translate-x-[82vw]">
                            <BurgerSVG handler={handler} lang={lang} theme={theme} $menu={$menu} />
                        </div>
                        <span className={`text-2xl ${theme ? 'text-TX2' : 'text-TX1'}`}>POSEIDON</span>
                    </div>
                </div>
            </div>
            <div className={`translate-y-16 z-10 fixed min:-[640px]:max-w-[320px] max-[640px]:w-[100vw] `}>
                {$menu &&
                    <div
                        className={`flex flex-col  ${theme ? 'bg-B22' : 'bg-B1'} w-full  h-screen relative`}>
                        <div className="sm:hidden flex items-center ml-6 justify-between">
                            <div className="flex items-center">
                                <UserSVG theme={theme} />
                                <span
                                    style={{ fontFamily: "Inter, sans-serif" }}
                                    className={`${theme ? 'text-TX2' : 'text-TX1'} text-base`}>{statistic.Account ? statistic.Account.Username : "unknown"}</span>
                            </div>
                            <div
                                className={` w-12 rounded-3xl border ${theme ? "border-TX2" : "border-TX1"}  rounden-2xl mr-6`}>
                                <div
                                    onClick={changeTheme}
                                    className={`${theme ? 'translate-x-[25px]' : ''} border ${theme ? "border-TX2" : "border-TX1"} cursor-pointer rounded-full w-5 h-5 bg-white transition `}></div>
                            </div>
                        </div>
                        <div className="sm:hidden ml-6 flex justify-between mt-2">
                            <div></div>
                            <div className="flex mr-6">
                                <select
                                    value={lang}
                                    onChange={(e) => setLang(e.target.value)}
                                    className={`${theme ? 'text-TX2' : 'text-TX1'} bg-transparent ml-2`}>
                                    <option
                                        className="bg-[#000]/40"
                                        value="us">US
                                    </option>
                                    <option
                                        className="bg-[#000]/40"
                                        value="ru">RU
                                    </option>
                                </select>
                                <div className="ml-1">
                                    <LangSVG lang={lang} />
                                </div>
                            </div>
                        </div>
                        <RouteLink
                            current={$currentPage === 'home'}
                            text={lang === 'ru' ? "Панель" : "Dashboard"}
                            path={'/?page=home'} SVG={<DashboardSVG current={$currentPage === 'home'} />} />
                        <RouteLink
                            current={$currentPage === 'token'}
                            text={lang === 'ru' ? "Восстановление Google Cookie" : "Google cookies restore"}
                            path={'/?page=token'} SVG={<GoogleCookiesSVG current={$currentPage === 'token'} />} />
                        <RouteLink
                            current={$currentPage === 'converter'}
                            text={lang === 'ru' ? "Конвертер файлов Сookie" : "Cookies Converter"}
                            path={'/?page=converter'} SVG={<ConverterSVG current={$currentPage === 'converter'} />} />
                        <RouteLink
                            current={$currentPage === 'settings'}
                            text={lang === 'ru' ? "Настройки" : "Settings"}
                            path={'/?page=settings'} SVG={<SettingSVG current={$currentPage === 'settings'} />} />
                        <RouteLink
                            current={$currentPage === 'builder'}
                            text={lang === 'ru' ? "Билдер" : "Builder"}
                            path={'/?page=builder'} SVG={<BuilderSVG current={$currentPage === 'builder'} />} />
                        <RouteLink
                            current={$currentPage === 'logs'}
                            text={lang === 'ru' ? "Логи" : "Logs"}
                            path={'/?page=logs'} SVG={<LogSVG current={$currentPage === 'logs'} />} />
                        <RouteLink
                            current={$currentPage === 'account'}
                            text={lang === 'ru' ? "Аккаунт" : "Account"}
                            path={'/?page=account'} SVG={<AccountSVG current={$currentPage === 'account'} />} />
                        {process.env.REACT_APP_DEV === "true" && 
                            <RouteLink
                            current={$currentPage === 'data'}
                            text={lang === 'ru' ? "Статистика" : "Data"}
                            path={'/?page=data'} SVG={<DataSVG current={$currentPage === 'data'} />} />
                        }
                    
                    </div>
                }
            </div>
        </>
    )
})