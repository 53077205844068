import React from 'react';

export const LangSVG = ({lang}) => {

    return <>
        {lang === 'ru' ?
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_64_550)">
                    <path
                        d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
                        fill="#F0F0F0"/>
                    <path
                        d="M33.9115 23.5869C34.615 21.6911 35 19.6406 35 17.5C35 15.3594 34.615 13.3089 33.9115 11.4131H1.08849C0.385068 13.3089 0 15.3594 0 17.5C0 19.6406 0.385068 21.6911 1.08849 23.5869L17.5 25.1087L33.9115 23.5869Z"
                        fill="#0052B4"/>
                    <path
                        d="M17.5 35C25.0244 35 31.4389 30.2509 33.9115 23.5869H1.0885C3.56113 30.2509 9.97563 35 17.5 35Z"
                        fill="#D80027"/>
                </g>
                <defs>
                    <clipPath id="clip0_64_550">
                        <rect width="35" height="35" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            :
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_64_498)">
                    <path
                        d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
                        fill="#F0F0F0"/>
                    <path d="M16.7393 17.4998H35.0001C35.0001 15.9203 34.7896 14.3901 34.3972 12.9346H16.7393V17.4998Z"
                          fill="#D80027"/>
                    <path d="M16.7393 8.36944H32.4316C31.3603 6.62136 29.9906 5.07623 28.3936 3.8042H16.7393V8.36944Z"
                          fill="#D80027"/>
                    <path
                        d="M17.5 34.9996C21.6186 34.9996 25.4042 33.5761 28.3935 31.1953H6.60657C9.59592 33.5761 13.3815 34.9996 17.5 34.9996Z"
                        fill="#D80027"/>
                    <path
                        d="M2.56858 26.6302H32.4315C33.2915 25.2268 33.9585 23.6929 34.3972 22.0649H0.602905C1.04157 23.6929 1.70855 25.2268 2.56858 26.6302Z"
                        fill="#D80027"/>
                    <path
                        d="M8.10633 2.73287H9.70108L8.21769 3.81056L8.78432 5.55434L7.30099 4.47665L5.81766 5.55434L6.30711 4.0479C5.00104 5.13584 3.85629 6.41047 2.91293 7.8307H3.42392L2.47967 8.51669C2.33256 8.7621 2.19146 9.01141 2.05625 9.2644L2.50715 10.6522L1.66592 10.041C1.45681 10.484 1.26554 10.937 1.09361 11.3995L1.59038 12.9285H3.42392L1.94052 14.0062L2.50715 15.75L1.02382 14.6723L0.135283 15.3179C0.0463477 16.0328 0 16.761 0 17.5H17.5C17.5 7.83508 17.5 6.69566 17.5 0C14.0429 0 10.8203 1.00283 8.10633 2.73287ZM8.78432 15.75L7.30099 14.6723L5.81766 15.75L6.38429 14.0062L4.90089 12.9285H6.73442L7.30099 11.1848L7.86755 12.9285H9.70108L8.21769 14.0062L8.78432 15.75ZM8.21769 8.90839L8.78432 10.6522L7.30099 9.57448L5.81766 10.6522L6.38429 8.90839L4.90089 7.8307H6.73442L7.30099 6.08692L7.86755 7.8307H9.70108L8.21769 8.90839ZM15.0615 15.75L13.5782 14.6723L12.0948 15.75L12.6615 14.0062L11.1781 12.9285H13.0116L13.5782 11.1848L14.1447 12.9285H15.9783L14.4949 14.0062L15.0615 15.75ZM14.4949 8.90839L15.0615 10.6522L13.5782 9.57448L12.0948 10.6522L12.6615 8.90839L11.1781 7.8307H13.0116L13.5782 6.08692L14.1447 7.8307H15.9783L14.4949 8.90839ZM14.4949 3.81056L15.0615 5.55434L13.5782 4.47665L12.0948 5.55434L12.6615 3.81056L11.1781 2.73287H13.0116L13.5782 0.989092L14.1447 2.73287H15.9783L14.4949 3.81056Z"
                        fill="#0052B4"/>
                </g>
                <defs>
                    <clipPath id="clip0_64_498">
                        <rect width="35" height="35" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        }
    </>
}