import React from 'react';

export const RoninSVG = () => {

    return <svg width="18" height="24" viewBox="0 0 22 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillrule="evenodd" clipRule="evenodd"
              d="M0 2.36932V21.3712C0.000222641 21.7262 0.0804854 22.0765 0.234859 22.3964C0.389232 22.7162 0.613772 22.9975 0.891892 23.2193L9.88514 30.4102C10.2015 30.6625 10.5947 30.8 11 30.8C11.4053 30.8 11.7985 30.6625 12.1149 30.4102L21.1081 23.2193C21.3862 22.9975 21.6108 22.7162 21.7651 22.3964C21.9195 22.0765 21.9998 21.7262 22 21.3712V2.36932C22 1.74093 21.7494 1.13829 21.3034 0.693956C20.8574 0.249624 20.2524 0 19.6216 0H2.37838C1.74759 0 1.14264 0.249624 0.696611 0.693956C0.250578 1.13829 0 1.74093 0 2.36932Z"
              fill="#2662D9"/>
        <path
            d="M17.4127 10.9788V6.83733C17.4127 6.28814 17.1965 5.76144 16.8115 5.37311C16.4265 4.98477 15.9044 4.7666 15.36 4.7666H6.63597C6.09155 4.7666 5.56944 4.98477 5.18448 5.37311C4.79952 5.76144 4.58325 6.28814 4.58325 6.83733V18.854C4.58344 19.1642 4.65272 19.4704 4.78595 19.75C4.91919 20.0296 5.11298 20.2753 5.35302 20.4692L8.5296 23.0421C8.56731 23.073 8.61292 23.0924 8.66114 23.0981C8.70936 23.1039 8.75821 23.0957 8.80201 23.0746C8.84581 23.0534 8.88276 23.0202 8.90858 22.9787C8.93439 22.9372 8.94801 22.8892 8.94784 22.8402V14.3437C8.94784 14.2751 8.97488 14.2092 9.023 14.1607C9.07112 14.1122 9.13638 14.0849 9.20443 14.0849H11.5137C11.922 14.0849 12.3136 14.2485 12.6024 14.5398C12.8911 14.831 13.0533 15.226 13.0533 15.6379V22.8402C13.0533 22.889 13.067 22.9367 13.0927 22.978C13.1185 23.0192 13.1553 23.0523 13.1988 23.0734C13.2424 23.0945 13.291 23.1028 13.339 23.0972C13.387 23.0917 13.4325 23.0726 13.4702 23.0421L16.6468 20.4692C16.8869 20.2753 17.0806 20.0296 17.2139 19.75C17.3471 19.4704 17.4164 19.1642 17.4166 18.854V15.1203C17.4166 14.5711 17.2003 14.0444 16.8154 13.656C16.4304 13.2677 15.9083 13.0495 15.3639 13.0495C15.9076 13.0485 16.4287 12.8299 16.8129 12.4417C17.197 12.0534 17.4127 11.5273 17.4127 10.9788ZM11.5112 12.0142H9.20187C9.13381 12.0142 9.06855 11.9869 9.02043 11.9384C8.97231 11.8898 8.94528 11.824 8.94528 11.7553V7.09617C8.94528 7.02753 8.97231 6.96169 9.02043 6.91315C9.06855 6.8646 9.13381 6.83733 9.20187 6.83733H12.7941C12.8622 6.83733 12.9274 6.8646 12.9756 6.91315C13.0237 6.96169 13.0507 7.02753 13.0507 7.09617V10.4611C13.0507 10.873 12.8885 11.268 12.5998 11.5593C12.3111 11.8505 11.9195 12.0142 11.5112 12.0142Z"
            fill="url(#paint0_linear_204_292)"/>
        <defs>
            <linearGradient id="paint0_linear_204_292" x1="10.9999" y1="4.7666" x2="10.9999" y2="23.0999"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="0.458333" stopColor="white"/>
                <stop offset="1" stopColor="white"/>
            </linearGradient>
        </defs>
    </svg>


}