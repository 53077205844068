import React from "react";

export const RodmacerSVG = () => {
    return <>

    <svg width="125" fill="#2EB8C4" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 112.7 135.94"><defs><linearGradient x1="56.35" y1="22.81" x2="57.01" y2="134.78" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#2eb8c4"/><stop offset="0.99" stopColor="#a5c715"/></linearGradient></defs><path  d="M112.15,51.16a50.65,50.65,0,0,1-1.49,8,42.64,42.64,0,0,1-4.34,10.08,2.26,2.26,0,0,1-.95.91c-.08,1.21-1,2-1.67,2.82a31.41,31.41,0,0,1-14.81,9.8,49.63,49.63,0,0,1-13,2.46c-8.89.44-17.79.07-26.68.2a28.7,28.7,0,0,0-5.12.77,7.46,7.46,0,0,0-1.32.41c-.42.26-.76.24-1.18.5-1.53,1.48-3.13,2.89-3.81,5a17.4,17.4,0,0,0-.87,5.25q0,18.65,0,37.29c0,.43.24,1-.25,1.23s-.74-.36-1-.65Q22.32,122,9,108.71A3.45,3.45,0,0,1,7.89,106c.06-5.36,0-10.73.05-16.09a33.4,33.4,0,0,1,9.11-23,33.73,33.73,0,0,1,15-9.34,34.24,34.24,0,0,1,9.61-1.42c5-.09,9.93.1,14.89-.08,4.52-.16,9-.07,13.54-.23s10-4.69,11.4-9A14.8,14.8,0,0,0,82,39.28a12.34,12.34,0,0,0-4.25-7,8.33,8.33,0,0,1-.83-.85l-2.93-1.59c-3.72-1.24-7.56-1.06-11.4-1.06-10.78,0-21.57,0-32.35,0a4.14,4.14,0,0,1-3.28-1.33c-8.62-8.71-17.32-17.35-26-26C.72,1.29.52,1.11.34.92S-.1.54,0,.24.49,0,.74,0H6.11c19,0,38,.06,56.92,0A53.17,53.17,0,0,1,89.09,6.2a45,45,0,0,1,17.62,16.63,18.1,18.1,0,0,1,3.44,6.26C113,36.24,113.11,43.64,112.15,51.16Z"/></svg>
    
     {/* <svg width="345" height="345" viewBox="0 0 466 466" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_442_2634)">
            <circle cx="233" cy="233" r="103" fill="url(#paint0_linear_442_2634)" fillOpacity="0.3"/>
        </g>
        <g clipPath="url(#clip0_442_2634)">
            <path
                d="M304.01 279.84V306C269.52 306 253.417 292.473 235.801 267.437C225.841 253.327 215.307 245.752 204.94 241.746V304.97H178.72V225.888V211.32C196.554 211.32 221.419 214.306 244.291 236.959C251.915 234.753 256.17 233.182 259.539 228.312C266.631 217.959 266.422 207.439 258.882 197.023C253.093 189.021 243.519 186.753 239.712 186.16H179.116L161 160H241.537L242.372 160.104C249.672 161.051 268.122 165.057 280.168 181.706C294.248 201.175 294.634 223.547 281.211 243.099C276.429 250.065 269.686 255.464 261.833 258.613C274.745 275.293 284.486 279.84 304.01 279.84Z"
                fill="url(#paint1_linear_442_2634)"/>
        </g>
        <defs>
            <filter id="filter0_f_442_2634" x="0" y="0" width="466" height="466" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="65" result="effect1_foregroundBlur_442_2634"/>
            </filter>
            <linearGradient id="paint0_linear_442_2634" x1="233" y1="130" x2="233" y2="336"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#2EB8C4"/>
                <stop offset="1" stopColor="#A1C71B"/>
            </linearGradient>
            <linearGradient id="paint1_linear_442_2634" x1="232.505" y1="306" x2="232.505" y2="160"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#2EB8C4"/>
                <stop offset="0.99" stopColor="#A5C715"/>
            </linearGradient>
            <clipPath id="clip0_442_2634">
                <rect width="143" height="146" fill="white" transform="translate(161 160)"/>
            </clipPath>
        </defs>
    </svg> */}


    </>
}