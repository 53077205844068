import React from "react";

export const DeleteSVG = () => {

    return <svg width="14" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM9.5 10.4142L6.20711 13.7071L4.79289 12.2929L8.08579 9L4.79289 5.70711L6.20711 4.29289L9.5 7.58579L12.7929 4.29289L14.2071 5.70711L10.9142 9L14.2071 12.2929L12.7929 13.7071L9.5 10.4142Z" fill="white" fillOpacity="0.9"/>
    </svg>
    
    

}