import React, { useState } from "react";
import { DownloadSVG } from "../SVG/downloadSVG";
import { DeleteSVG } from "../SVG/deleteSVG";
import { Checkbox } from "../UI/checkbox";
import ReactCountryFlag from "react-country-flag"

import { useLogerStore } from "../../store/logerState";
import { useLocalStore } from "../../store/localState";
import { NoteSVG } from "../SVG/noteSVG";
import { WebSVG } from "../SVG/webSVG";
import { AtomicSVG } from "../SVG/wallets/atomicSVG";
import { BinanceSVG } from "../SVG/wallets/binanceSVG";
import { CoinbaseSVG } from "../SVG/wallets/coinbaseSVG";
import { ElectumSVG } from "../SVG/wallets/electumSVG";
import { ExodusSVG } from "../SVG/wallets/exodusSVG";
import { LedgerSVG } from "../SVG/wallets/ledgerSVG";
import { MartianSVG } from "../SVG/wallets/martianSVG";
import { MetaMaskSVG } from "../SVG/wallets/metamaskSVG";
import { PhantomSVG } from "../SVG/wallets/phantomSVG";
import { RoninSVG } from "../SVG/wallets/roninSVG";
import { TronSVG } from "../SVG/wallets/tronSVG";
import { TrustSVG } from "../SVG/wallets/trustSVG";
import { SafePalSVG } from "../SVG/wallets/safepalSVG";
import { UnisatSVG } from "../SVG/wallets/unisatSVG";
import { OkxSVG } from "../SVG/wallets/okxSVG";
import { BitgetSVG } from "../SVG/wallets/bitgetSVG";
import { KeplrSVG } from "../SVG/wallets/keplrSVG";
import { CryptocomSVG } from "../SVG/wallets/cryptocomSVG";

export const Log = ({ data, id }) => {
    const store = useLogerStore((state) => state);
    const { theme, token } = useLocalStore((state) => state);

    const [wallets] = useState(w());

    function w() {
        const wt = data.Wallets.length > 0 ? data.Wallets.map(w => w.slice(0,6).toLowerCase()) : []
        const newSet = new Set(wt);
        return Array.from(newSet);
    }
    function showWallet(wallet) {

        let wt = wallet.toLowerCase()
        if (wt.includes("atomi")) {
            return <AtomicSVG />
        } else if (wt.includes("bina")) {
            return <BinanceSVG />
        } else if (wt.includes("coin")) {
            return <CoinbaseSVG theme={theme} />
        } else if (wt.includes("elec")) {
            return <ElectumSVG />
        } else if (wt.includes("exod")) {
            return <ExodusSVG />
        } else if (wt.includes("ledg")) {
            return <LedgerSVG theme={theme} />
        } else if (wt.includes("mart")) {
            return <MartianSVG theme={theme} />
        } else if (wt.includes("meta")) {
            return <MetaMaskSVG />
        } else if (wt.includes("pha")) {
            return <PhantomSVG />
        } else if (wt.includes("roni")) {
            return <RoninSVG />
        } else if (wt.includes("tron")) {
            return <TronSVG theme={theme} />
        } else if (wt.includes("trus")) {
            return <TrustSVG />
        } else if (wt.includes("safe")) {
            return <SafePalSVG theme={theme} />
        } else if (wt.includes("unis")) {
            return <UnisatSVG theme={theme} />
        } else if (wt.includes("okx")) {
            return <OkxSVG theme={theme} />
        } else if (wt.includes("bitg")) {
            return <BitgetSVG />
        } else if (wt.includes("kepr")) {
            return <KeplrSVG />
        } else if (wt.includes("cryp")) {
            return <CryptocomSVG theme={theme} />
        } else {
            return wt.slice(0, 4).toUpperCase() + ".."
        }
    }

    const [c, set] = useState(data.Comment)

    async function pushComment(id) {
        const response = await fetch(`/authed/comment/`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({
                ID: id,
                Message: c
            })
        })
    }

    async function deleteMarked(log) {
        const response = await fetch(`/authed/deleteMarked/?id=${log}&auth=${token}`)
                .then(res => store.setFilterData([log]))
    }

    function fillStatus(status) {

        if (!status) {
                return <div className='flex my-4 justify-between items-center max-w-20 rounded-xl px-2 border-2 border-AD'>
                <div className='mr-2'>
                    <div className='w-2 h-2 rounded-full bg-AD'></div>
                </div>
                <div className="text-AD">Open</div>
             </div>
        } else {
            if (theme) {
                return  <div className='flex my-4 justify-between items-center max-w-20 rounded-xl px-2 border-2 border-S2'>
                    <div className='mr-2'>
                        <div className='w-2 h-2 rounded-full bg-S2'></div>
                    </div>
                    <div className="text-S2">New</div>
                </div>
            } else {
                return  <div className='flex my-4 justify-between items-center max-w-20 rounded-xl px-2 border-2 border-S1'>
                    <div className='mr-2'>
                        <div className='w-2 h-2 rounded-full bg-S1'></div>
                    </div>
                    <div className="text-S1">New</div>
                </div>
            }
        }
    }

    return (
        <>
            <div
                className={`flex justify-between ${theme ? "text-0F" : "text-white"} py-2  border-b ${theme ? "border-[#D8D8D8]" : "border-[#FFFFFF]/60"} min-w-[860px] `}>
                <div className='w-1/12 flex flex-col justify-between relative z-0 '>
                    <div className="break-words w-full">{data.LogID}</div>
                    {fillStatus(data.LogStatus)}
                    <div className='flex gap-4'>
                        <div>{id}</div>
                        <Checkbox theme={theme} handler={() => store.setChecked(data.LogID)}
                            checked={store.checked.indexOf(data.LogID) !== -1 ? true : false} value={data.id} />
                    </div>
                </div>
                <div className='mx-2 w-1/12 flex justify-center'>
                    <div className="mr-2 flex items-center gap-2">{data.Country !== null
                    ? <ReactCountryFlag  countryCode={data.CountryCode}/>
                    : <div>&#127988;</div>} {data.Country}</div>
                </div>
                <div className='mx-2 w-1/12 flex items-center break-all'>{data.IP}</div>
                <div className='mx-2 w-3/12 flex flex-col'>
                    <div className="flex flex-wrap">
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">Notes: {data.Notes ? "YES" : "NO"}</div>
                        </div>
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">isDup: {data.IsDup ? "YES" : "NO"}</div>
                        </div>
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">Cookies: {data.Cookies}</div>
                        </div>
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">Passwords: {data.Passwords}</div>
                        </div>
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">Autofills: {data.Autofills}</div>
                        </div>
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">Social: {data.Social}</div>
                        </div>
                        <div className="flex items-center mr-4">
                            <NoteSVG theme={theme} />
                            <div className="ml-1 text-nowrap">Cards: {data.Cards}</div>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-2">
                        {data.ValueableDomains[0] !== "nothing" ? data.ValueableDomains.map(d => <div className={`flex gap-1 mr-1 justify-center items-center ${theme ? "text-[#0S293F]" : "text-[#29E7A8]"}`}><WebSVG theme={theme} /> {d}</div>) : ""}
                    </div>
                </div>
                <div className='mx-2 w-2/12 flex  items-center flex-wrap'>
                    {data.WalletCount} → <div>&nbsp;</div> {wallets.map(w => <div
                        className={`h-6 mr-2`}>{showWallet(w)}</div>)}
                </div>
                <div className='mx-2 w-1/12 flex items-center justify-center'>
                    {data.BuildID}
                </div>
                <div className='mx-2 w-1/12 flex flex-col justify-center items-center'>
                    <div>
                        <a
                            target="_blank"
                            href={`/authed/cron/download_single?id=${data.LogID}&auth=${token}`}
                            className={`flex items-center mt-2 px-1 p-1 rounded-sm ${theme ? 'bg-S2' : 'bg-D2'} text-white text-xs`}>
                            <div className="mr-1"><DownloadSVG /></div>
                            Download</a>
                    </div>
                    <div>
                        <a
                            onClick={() => deleteMarked(data.LogID)}
                            className={`flex items-center mt-2 px-1 p-1 rounded-sm bg-AD text-white text-xs cursor-pointer`}>
                            <div className="mr-1"><DeleteSVG /></div>
                            Delete</a>
                    </div>
                </div>
                <div className='mx-2 w-1/12 flex justify-center items-center break-all'>
                    {data.Timegot}
                </div>
                <div className='mx-2 w-1/12 flex'>
                    <textarea
                        onChange={(e) => set(e.target.value)}
                        className={`min-w-20 p-2 resize-none bg-transparent outline-none border-2 ${theme ? "border-S2" : "border-S1"}`}>{c}</textarea>
                    <button
                        onClick={() => pushComment(data.LogID)}
                        className={`${theme ? "bg-S2" : "bg-S1"} w-1/6 flex justify-center items-center`}>
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 9.5L5 12.5L14 1.5" stroke="white" strokeWidth="3" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}