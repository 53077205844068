import React from 'react';

export const KeplrSVG = () => {

    return <svg height="18" viewBox="0 0 119 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M52.6748 29.0638V20.026L61.5769 29.0638H66.5364V28.8292L56.2963 18.5377L65.7387 8.77111V8.65375H60.7532L52.6709 17.2839V8.65375H48.6646V29.0638H52.6748ZM73.4144 29.5003C75.7236 29.5003 78.2443 28.7139 79.8556 27.1678L77.4845 24.9237C76.605 25.7679 74.8422 26.2698 73.4742 26.2698C70.8319 26.2698 69.2505 24.9872 69.0092 23.2085H80.6951C80.727 22.7431 80.7569 22.3047 80.7569 21.8624C80.7569 16.8474 77.7178 14.3111 73.1312 14.3111C68.2694 14.3111 65.1704 17.4589 65.1704 21.8336C65.1764 26.4101 68.2454 29.5003 73.4104 29.5003H73.4144ZM77.1515 20.2587H69.065C69.6414 18.3915 71.2227 17.4589 73.2528 17.4589C75.4145 17.4589 76.9062 18.3915 77.1475 20.2587H77.1515ZM86.0676 35.6518V27.3716C87.1601 28.9754 89.4395 29.4715 91.0528 29.4715C95.8553 29.4715 98.6188 26.089 98.6188 21.8913C98.6188 17.6608 95.5498 14.3687 90.931 14.3687C89.2299 14.3687 87.256 15.0399 86.0716 16.6762L85.8279 14.6937H82.3561V35.6537L86.0676 35.6518ZM90.654 26.0025C88.1016 26.0025 86.4006 24.1353 86.4006 21.8913C86.4006 19.6472 87.9822 17.8089 90.654 17.8089C93.3265 17.8089 94.9073 19.6453 94.9073 21.8913C94.9073 24.1372 93.203 26.0025 90.6499 26.0025H90.654ZM104.129 29.0638V8.6807H100.447V29.0638H104.129ZM110.582 29.0638V21.4836C110.582 18.7915 112.376 17.83 114.319 17.83C115.535 17.83 116.233 18.2146 116.994 18.7645L118.665 15.6725C117.845 14.9437 116.355 14.3265 114.806 14.3265C113.318 14.3265 111.677 14.588 110.582 16.3378L110.309 14.6764H106.87V29.0503L110.582 29.0638Z"
            fill="white"/>
        <path
            d="M0 17C0 10.4531 1.00795e-07 7.17973 1.4736 4.77501C2.29816 3.42946 3.42945 2.29816 4.77501 1.47359C7.17971 -2.04599e-07 10.4531 0 17 0H23C29.5468 0 32.8203 -2.04599e-07 35.225 1.47359C36.5705 2.29816 37.7018 3.42946 38.5263 4.77501C40 7.17973 40 10.4531 40 17V23C40 29.5469 40 32.8203 38.5263 35.225C37.7018 36.5706 36.5705 37.7019 35.225 38.5264C32.8203 40 29.5468 40 23 40H17C10.4531 40 7.17971 40 4.77501 38.5264C3.42945 37.7019 2.29816 36.5706 1.4736 35.225C1.00795e-07 32.8203 0 29.5469 0 23V17Z"
            fill="url(#paint0_linear_254_22539)"/>
        <path
            d="M0 17C0 10.4531 1.00795e-07 7.17973 1.4736 4.77501C2.29816 3.42946 3.42945 2.29816 4.77501 1.47359C7.17971 -2.04599e-07 10.4531 0 17 0H23C29.5468 0 32.8203 -2.04599e-07 35.225 1.47359C36.5705 2.29816 37.7018 3.42946 38.5263 4.77501C40 7.17973 40 10.4531 40 17V23C40 29.5469 40 32.8203 38.5263 35.225C37.7018 36.5706 36.5705 37.7019 35.225 38.5264C32.8203 40 29.5468 40 23 40H17C10.4531 40 7.17971 40 4.77501 38.5264C3.42945 37.7019 2.29816 36.5706 1.4736 35.225C1.00795e-07 32.8203 0 29.5469 0 23V17Z"
            fill="url(#paint1_radial_254_22539)"/>
        <path
            d="M0 17C0 10.4531 1.00795e-07 7.17973 1.4736 4.77501C2.29816 3.42946 3.42945 2.29816 4.77501 1.47359C7.17971 -2.04599e-07 10.4531 0 17 0H23C29.5468 0 32.8203 -2.04599e-07 35.225 1.47359C36.5705 2.29816 37.7018 3.42946 38.5263 4.77501C40 7.17973 40 10.4531 40 17V23C40 29.5469 40 32.8203 38.5263 35.225C37.7018 36.5706 36.5705 37.7019 35.225 38.5264C32.8203 40 29.5468 40 23 40H17C10.4531 40 7.17971 40 4.77501 38.5264C3.42945 37.7019 2.29816 36.5706 1.4736 35.225C1.00795e-07 32.8203 0 29.5469 0 23V17Z"
            fill="url(#paint2_radial_254_22539)"/>
        <path
            d="M0 17C0 10.4531 1.00795e-07 7.17973 1.4736 4.77501C2.29816 3.42946 3.42945 2.29816 4.77501 1.47359C7.17971 -2.04599e-07 10.4531 0 17 0H23C29.5468 0 32.8203 -2.04599e-07 35.225 1.47359C36.5705 2.29816 37.7018 3.42946 38.5263 4.77501C40 7.17973 40 10.4531 40 17V23C40 29.5469 40 32.8203 38.5263 35.225C37.7018 36.5706 36.5705 37.7019 35.225 38.5264C32.8203 40 29.5468 40 23 40H17C10.4531 40 7.17971 40 4.77501 38.5264C3.42945 37.7019 2.29816 36.5706 1.4736 35.225C1.00795e-07 32.8203 0 29.5469 0 23V17Z"
            fill="url(#paint3_radial_254_22539)"/>
        <path
            d="M15.2654 31.0001V21.2562L24.7335 31.0001H30V30.7472L19.1108 19.6539L29.16 9.1265V9.00006H23.8596L15.2654 18.3025V9.00006H11V31.0001H15.2654Z"
            fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_254_22539" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor="#1FD1FF"/>
                <stop offset="1" stopColor="#1BB8FF"/>
            </linearGradient>
            <radialGradient id="paint1_radial_254_22539" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(1.91069 38.4844) rotate(-45.1556) scale(64.1474 65.107)">
                <stop stopColor="#232DE3"/>
                <stop offset="1" stopColor="#232DE3" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint2_radial_254_22539" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(37.8456 39.7716) rotate(-138.45) scale(40.1083 61.1539)">
                <stop stop-color="#8B4DFF"/>
                <stop offset="1" stopColor="#8B4DFF" stopOpacity="0"/>
            </radialGradient>
            <radialGradient id="paint3_radial_254_22539" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(19.6668 0.296664) rotate(90) scale(31.5366 76.5164)">
                <stop stop-color="#24D5FF"/>
                <stop offset="1" stopColor="#1BB8FF" stopOpacity="0"/>
            </radialGradient>
        </defs>
    </svg>

}