import React from "react";

export const Textarea = ({ theme, text, onChange, placeholder, value }) => {

    return <div className="w-72 mr-8">
        <textarea
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className={`max-w-72 w-full h-16 p-3 resize-none text-xs rounded-xl border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`} type="text" />
        <label className={`rounded-2xl text-xs p-1 z-0 relative top-[-83px] left-8  absolute  ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}>{text}</label>
    </div>
}