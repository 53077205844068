import React, { useEffect } from 'react';
import { useLocalStore } from "../../store/localState";
import { useLogerStore } from "../../store/logerState";
import { Container } from '../../components/Page';

export const Home = () => {

    const { theme, lang } = useLocalStore((state) => state);
    const { Account, Stats } = useLogerStore(state => state.statistic)
    useEffect(() => {
        document.title = 'Home';
    }, []);

    function mapState(state) {
        const result = [];
        for (let key in state) {
            if (key === "Total") continue
            result.push(<div
                className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "border-grey-box" : ""} flex justify-between  mt-4 rounded-xl p-3`}
                key={key}><span>{key}</span><span>{state[key]}</span></div>)
        }
        return result
    };

    return (
        <Container styles={` m-0 flex justify-center items-center ${theme ? "text-TX2" : "text-TX1"}`}>
            <div className={"w-4/6 flex justify-center gap-10 max-[900px]:flex-col max-[900px]:items-center my-16"}>
                <div
                    className={`w-2/3`}>
                    <h1 className={`m-0 text-2xl  mt-8`}>{lang === 'ru' ? 'Статистика' : 'Statistic'}</h1>
                    <div>
                        <div
                            className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "border-grey-box" : ""} flex justify-between  mt-4 rounded-xl p-3`}
                        ><span>Total</span><span>{Stats ? Stats.Total : "0"}</span></div>
                        {mapState(Stats)}
                    </div>
                </div>
            </div>
        </Container>
    )
}