import React from 'react';

export const SafePalSVG = ({theme}) => {

    return <svg width="24" height="24" viewBox="0 0 491 560" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_402_4)">
            <path fillrule="evenodd" clipRule="evenodd"
                  d="M490.395 324.294V363.842C490.395 474.576 329.04 537.853 280 553.672L262.599 560V491.977L272.09 488.814C349.605 460.339 427.119 411.299 427.119 363.842V324.294H490.395ZM245.198 0L311.638 20.565V90.1695L245.198 69.6045L240.452 71.1864V227.797H310.056V292.655H240.452V558.418L221.469 552.09C213.559 548.927 199.322 544.181 181.921 536.271L174.011 533.107V20.565L245.198 0ZM155.028 28.4746V98.0791L64.8587 124.972V227.797H155.028V526.78L134.463 517.288C72.7684 485.65 0 433.446 0 359.096V324.294H64.8587V359.096C64.8587 374.915 72.7684 390.734 88.5876 406.554V292.655H0V74.3503L155.028 28.4746ZM330.621 26.8927L488.814 75.9322V294.237H395.48V389.153C395.48 389.153 374.915 416.045 330.621 438.192C330.621 425.537 330.621 234.124 330.621 117.062V26.8927ZM397.062 115.48V227.797H425.537V124.972L397.062 115.48Z"
                  fill={theme ? "black" : "white"}/>
        </g>
        <defs>
            <clipPath id="clip0_402_4">
                <rect width="490.395" height="560" fill="white"/>
            </clipPath>
        </defs>
    </svg>


}