import React from 'react';
import { useLocalStore } from "../../store/localState";
import { ResponseMessage } from './message';

export const Response = ({ error }) => {

    const { theme, lang } = useLocalStore(state => state)

     console.log(error)

    return <>{
        error !== null &&
        <div className="w-full justify-center items-center my-2">
            {error
                ? <ResponseMessage theme={theme} error={error}
                    message={lang === 'ru' ? "ОШИБКА" : "ERROR"} />
                : <ResponseMessage theme={theme} error={error}
                    message={lang === 'ru' ? "ВЫПОЛНЕНО" : "SUCCESS"} />
            }
        </div>
    }
    </>
}