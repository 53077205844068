import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLogerStore } from "../../store/logerState";
import { Textarea } from "../UI/textarea";
import { useModalStore } from "../../store/modalState";
import { useLocalStore } from "../../store/localState";
import DatePicker from "react-datepicker";
import { CalendarSVG } from "../SVG/calendarSVG";


export const SearchModal = () => {

    const store = useLogerStore((state) => state)
    const { handleSearch } = useModalStore((state) => state);
    const { theme, lang } = useLocalStore(state => state)

    function search() {
        store.clearData();
        store.offsetClear();
        store.fetchData();
    }

    return (
        <div className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15  flex justify-center items-center  w-screen h-screen  z-10 top-0 right-0 fixed`}>
            <div className={`w-full h-[76vh] min-w-[435px] max-w-[1000px] ${theme ? "bg-white" : "bg-B1"}  rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                <div
                    className={`h-2/12 flex justify-between mx-5 px-5 pt-8 py-6 border-b-2 border-dashed ${theme ? "border-black" : "border-white"}`}>
                    <div className="flex justify-between items-center flex-wrap">
                        <div className="flex justify-around">
                            <div className="mb-4">
                                <label
                                    className={`rounded-2xl text-xs p-1 z-10 translate-x-4 translate-y-[-15px] absolute ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}>{lang === 'us' ? "Date From" : "Дата Начало"}</label>
                                <DatePicker
                                    className={`mr-3 px-2 py-1 w-32 rounded-2xl z-0 border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}
                                    selected={store.dateFrom}
                                    onChange={(date) => store.setDateFrom(date)}
                                />
                                <div className="translate-x-24 translate-y-[-25px] absolute">
                                    <CalendarSVG theme={theme} />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label
                                    className={`rounded-2xl text-xs p-1 z-10 translate-x-4 translate-y-[-15px] absolute ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}>{lang === 'us' ? "Time From" : "Время Начало"}</label>
                                <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    dateFormat="H-mm aa"
                                    timeFormat="HH:mm"
                                    className={`mr-3 px-2 py-1 rounded-2xl z-0 w-24 border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}
                                    selected={store.timeFrom}
                                    onChange={(date) => store.setTimeFrom(date)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-around">
                            <div className="mb-4 w-30">
                                <label
                                    className={`rounded-2xl text-xs p-1 z-10 translate-x-4 translate-y-[-15px] absolute ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"} ${theme ? "shadow" : ""}`}>{lang == 'us' ? "Date To" : "Дата Конец"}</label>
                                <DatePicker
                                    className={`mr-3 px-2 py-1 w-32 rounded-2xl z-0 border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}
                                    selected={store.dateTo}
                                    onChange={(date) => store.setDateTo(date)}
                                />
                                <div className="translate-x-24 translate-y-[-25px] absolute">
                                    <CalendarSVG theme={theme} />
                                </div>
                            </div>
                            <div className="mb-4 w-30">
                                <label
                                    className={`rounded-2xl text-xs p-1 z-10 translate-x-4 translate-y-[-15px] absolute ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}>{lang == 'us' ? "Time To" : "Время Конец"}</label>
                                <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    dateFormat="H-mm aa"
                                    timeFormat="HH-mm"
                                    className={`mr-3 px-2 py-1 rounded-2xl z-0 w-24 border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}
                                    selected={store.timeTo}
                                    onChange={(date) => store.setTimeTo(date)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex realtive z-10 mb-4 max-[800px]:flex-col">
                        <div className="flex">
                            <button
                                data-tag="HOUR"
                                onClick={(event) => {
                                    store.setDateTime(event.target.dataset.tag)
                                }}
                                className={`${store.dateTime.HOUR ? "!bg-[#000]/15" : ""} min-[800px]:rounded-s-xl  h-10 text-center align-center px-3 ${theme ? "" : "text-white"} ${theme ? "" : "bg-grey-box"}`}>{lang == 'us' ? "HOUR" : "ЧАС"}</button>
                            <button
                                data-tag="DAY"
                                onClick={(event) => {
                                    store.setDateTime(event.target.dataset.tag)
                                }}
                                className={`${store.dateTime.DAY ? "!bg-[#000]/15" : ""} h-10 text-center align-center px-2 ${theme ? "" : "text-white"} ${theme ? "" : "bg-grey-box"}`}>{lang === 'us' ? "DAY" : "ДЕНЬ"}</button>

                            <button
                                data-tag="WEEK"
                                onClick={(event) => {
                                    store.setDateTime(event.target.dataset.tag)
                                }}
                                className={`${store.dateTime.WEEK ? "!bg-[#000]/15" : ""} h-10 text-center align-center px-2 ${theme ? "" : "text-white"} ${theme ? "" : "bg-grey-box"}`}>{lang === 'us' ? "WEEK" : "НЕДЕЛЯ"}</button>

                        </div>
                        <div className="flex">
                            <button
                                data-tag="MONTH"
                                onClick={(event) => {
                                    store.setDateTime(event.target.dataset.tag)
                                }}
                                className={`${store.dateTime.MONTH ? "!bg-[#000]/15" : ""} h-10 text-center align-center px-2 ${theme ? "" : "text-white"} ${theme ? "" : "bg-grey-box"}`}>{lang === 'us' ? "MONTH" : "МЕСЯЦ"}</button>


                            <button
                                data-tag="YEAR"
                                onClick={(event) => {
                                    store.setDateTime(event.target.dataset.tag)
                                }}
                                className={`${store.dateTime.YEAR ? "!bg-[#000]/15" : ""} h-10 text-center align-center px-2 ${theme ? "" : "text-white"} ${theme ? "" : "bg-grey-box"}`}>{lang === 'us' ? "YEAR" : "ГОД"}</button>
                            <button
                                data-tag="ALL"
                                onClick={(event) => {
                                    store.setDateTime(event.target.dataset.tag)
                                }}
                                className={`${store.dateTime.ALL ? "!bg-[#000]/15" : ""} min-[800px]:rounded-e-xl  h-10 text-center align-center px-2 ${theme ? "" : "text-white"} ${theme ? "" : "bg-grey-box"}`}>{lang === 'us' ? "ALL" : "ВСЕ"}</button>

                        </div>
                    </div>
                </div>

                <div className="h-8/12 overflow-y-scroll">
                    <div
                        className={`flex flex-wrap mx-5 my-8   border-b-2 border-dashed ${theme ? "border-black" : "border-white"}`}>
                        
                            <Textarea
                                value={store.domains}
                                onChange={(e) => store.setDomains(e.target.value)}
                                placeholder={"google.com, opendns.com"} theme={theme}
                                text={lang === 'ru' ? "Доменыы" : "Domains"} />
                            <Textarea
                                value={store.country}
                                onChange={(e) => store.setCountry(e.target.value)}
                                placeholder={"EN,US,GE,NL"} theme={theme} text={lang === 'ru' ? "Страны" : "Country"} />

                      
                            <Textarea
                                value={store.delCountry}
                                onChange={(e) => store.setDelCountry(e.target.value)}
                                placeholder={"EN,US,GE,NL"} theme={theme}
                                text={lang === 'ru' ? "Удалить Страны" : "Del Contry"} />
                            <Textarea
                                value={store.wallets}
                                onChange={(e) => store.setWallets(e.target.value)}
                                placeholder={"electrum, metamask, exodus"} theme={theme}
                                text={lang === "ru" ? "Кошельки" : "Wallets"} />

                        
                            <Textarea
                                value={store.buildTags}
                                onChange={(e) => store.setBuildTags(e.target.value)}
                                placeholder={"SM, N/A"} theme={theme} text={lang === 'ru' ? "Билд Тэги" : "Build Tags"} />
                            <Textarea
                                value={store.logID}
                                onChange={(e) => store.setLogID(e.target.value)}
                                placeholder={"32ef07cf-ff86-4cd8-b45d-c16257438cfb"} theme={theme} text={"Log ID"} />

                    </div>
                    <div className="flex items-center m-10 gap-4 ">
                        <div className="mr-5">
                            <select
                                value={store.status}
                                onChange={(e) => store.setStatus(e.target.value)}
                                className={`max-w-24 w-full py-2 rounded-xl bg-transparent text-center ${theme ? "text-black" : "text-white"}`}>
                                <option
                                    value="">Status
                                </option>
                                <option
                                    value="Open">Open
                                </option>
                                <option
                                    value="New">New
                                </option>
                            </select>
                        </div>
                        <div className="mr-5">
                            <input
                                value={store.ip}
                                onChange={(e) => store.setIP(e.target.value)}
                                className={`mr-3 px-2 py-2 rounded-xl z-10 max-w-55 w-full border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}
                                placeholder="IP" type="text" />
                        </div>
                        <div className="mr-5">
                            <input
                                value={store.comment}
                                onChange={(e) => store.setComment(e.target.value)}
                                className={`mr-3 px-2 py-2 rounded-xl z-10 max-w-55 w-full border-none outline-none ${theme ? "bg-transparent" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow" : ""}`}
                                placeholder="Comment" type="text" />
                        </div>
                    </div>
                </div>
                <div
                    className={`mx-10 mb-4 h-1/12  pt-4 flex justify-between border-t-2 border-dashed ${theme ? "border-black" : "border-white"}`}>
                    <span></span>
                    <div className={`flex flex-wrap justify-end gap-5`}>
                        <button
                            onClick={() => search()}
                            className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} p-2 bg-transparent rounded-2xl`}>{lang == 'us' ? "SEARCH" : "ПОИСК"}</button>
                        <button
                            onClick={() => store.clear()}
                            className={`${theme ? "text-S2" : "text-S1"} font-semibold  border ${theme ? "border-S2" : "border-S1"} p-2 bg-transparent rounded-2xl`}>{lang == 'us' ? "RESET" : "ОБНОВИТЬ"}</button>
                        <button
                            onClick={() => handleSearch()}
                            className={`${theme ? "text-AD" : "text-AD"} font-semibold  border ${theme ? "border-AD" : "border-AD"} p-2 bg-transparent rounded-2xl`}>{lang == 'us' ? "CANCEL" : "ОТМЕНИТЬ"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}