import React, { useEffect, useState, useRef } from "react";
import { useLocalStore } from "../../store/localState";
import { useModalStore } from "../../store/modalState";
import { useLogerStore } from "../../store/logerState";
import { Response } from "../../components/response-message";
import { Container } from "../../components/Page";
import { Link } from "react-router-dom";

export const Account = () => {
    const linkRef = useRef(null)
    const { theme, lang, token, $payTags, setPayTags } = useLocalStore(store => store);
    const { Account } = useLogerStore((store) => store.statistic);
    const [viewPayBox, setViewPayBox] = useState(false);
    const [errorFetchPass, setErrorFetchPass] = useState(null);
    const [pass, setPass] = useState({ new_pass: "", confirm: "" });
    const [pay, setPay] = useState(null)
    const [buyAlert, setBuyAlert] = useState(false)
    const [errorValidate, setErrorValidate] = useState(null)

    useEffect(() => {
        document.title = "Account";
    }, []);

    async function createPay(sum) {
        if (!sum || (sum < 100)) return
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/pay/create_pay/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ amount: sum })
        }).then((res) => {
            return res.json()
        }).then(d => {
            setPayTags(d)
            setViewPayBox(true)
            linkRef.current.click()
        })
    };



    async function changePass(e) {
        e.preventDefault()
        if (pass.new_pass.length < 8) {
            return setErrorValidate("Password must contain at least 8 characters")
        }
        if (pass.new_pass !== pass.confirm) {
            return setErrorValidate("Passwords do not match.")
        }
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/change_pass/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ password: pass.new_pass })
        }).then((res) => {
            if (res.status < 306) {
                setErrorFetchPass(false);
                setErrorValidate(null)
                setPass({ new_pass: "", confirm: "" });
            }
            return res.json()
        }).catch(() => {
            setErrorValidate(null)
            setErrorFetchPass(true);
            setPass({ new_pass: "", confirm: "" });
        })
    };

    async function p() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/pay/buy_sub/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        }).then(res => {
            if (res.status < 304) {
                setBuyAlert(false)
                window.location.reload()
            }
        })
    }

    return <Container styles={"flex justify-center flex-wrap  gap-10"}>

        {buyAlert && <div
            className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
            <div
                className={`w-86 ${theme ? "bg-B2" : "bg-B1"} px-10 py-5 rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                <span className={` ${theme ? "text-S1" : "text-S1"} flex items-center`}>
                    <svg fill="currentColor" className="flex-shrink-0 inline w-4 h-4 me-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    CONFIRM PAY?
                </span>
                <div
                    className={`flex gap-10 mt-4 justify-center items-center ${theme ? "text-S2" : "test-S1"}`}>
                    <button
                        onClick={(e) => p()}
                        className={`px-4 py-2 border-2 ${theme ? "text-S2" : "text-S1"} ${theme ? "border-S2" : "border-S1"}`}>
                        YES
                    </button>
                    <button
                        onClick={(e) => setBuyAlert(false)}
                        className={`px-4 py-2 border-2 ${theme ? "text-S2" : "text-S2"} ${theme ? "border-S2" : "border-S2"}`}>
                        NO
                    </button>
                </div>
            </div>
        </div>}

        <div className="flex flex-col justify-between gap-10 mt-10 px-5">
            <div className={`${theme ? "border-S2" : "border-S1"} border-2 rounded-xl`}>
                {!viewPayBox
                    ? <div className={`p-10 ${theme ? "text-S2" : "text-S1"}`}>
                        <div
                            className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"} flex justify-between  mt-4 rounded-xl p-3`}
                        ><span>{lang === 'ru' ? "Имя пользователя" : "Username"}:</span><span>{Account ? Account.Username : "unknown"}</span></div>
                        <div
                            className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"}  flex justify-between  mt-4 rounded-xl p-3`}
                        ><span>{lang === 'ru' ? "Тип аккаунта" : "Account Type"}:</span><span>{Account ? Account.AccountType : "NULL"}</span></div>
                        <div
                            className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"} flex justify-between  mt-4 rounded-xl p-3`}
                        ><span>{lang === 'ru' ? "Баланс" : "Balance"}:</span><span>{Account ? Account.Balance : "0"}$</span></div>
                        <div
                            className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"} flex justify-between  mt-4 rounded-xl p-3`}
                        ><span>{lang === 'ru' ? "Срок действия" : "Sub Expiry"}:</span><span>{Account ? Account.SubExpiry : "NULL"}</span></div>
                        <div
                            className={`${theme ? "shadow-lg" : ''}  ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "border-grey-box" : ""} flex justify-between items-center  mt-4 rounded-xl p-3`}
                        ><span>{lang === 'ru' ? "Анти-дубликат" : "Anti Duplicate"}:</span>
                            <div
                                className={`w-5 h-5 rounded-md ${!Account || !Account.AntiDuplicate ? 'bg-AD' : theme ? 'bg-S2' : "bg-S1"}`}></div>
                        </div>
                        <div className={``}>
                            <span className={`${theme ? "text-S2" : "text-S1"} block mt-8 mb-4 text-xl`}>{lang === 'ru' ? "СМЕНА ПАРОЛЯ" : "CHANGE PASSWORD"}</span>
                            <div className="flex gap-20">
                                <input
                                    type="password"
                                    value={pass.new_pass}
                                    onChange={(e) => setPass({ ...pass, new_pass: e.target.value })}
                                    placeholder="New Password" className={`${theme ? "shadow-lg" : ''} ${theme ? "text-S2" : "text-S1"} ${theme ? "bg-white" : "bg-grey-box"} border ${theme ? "" : "border-S1"}  outline-none w-full flex justify-between  rounded-xl p-2`} />
                                <input
                                    type="password"
                                    value={pass.confirm}
                                    onChange={(e) => setPass({ ...pass, confirm: e.target.value })}
                                    placeholder="Confirm" className={`${theme ? "shadow-lg" : ''} ${theme ? "text-S2" : "text-S1"} ${theme ? "bg-white" : "bg-grey-box"} border ${theme ? "" : "border-S1"}  outline-none w-full flex justify-between rounded-xl p-2`} />
                            </div>
                            <div className="flex gap-20 mt-4 w-full">
                                <div className="w-full"></div>
                                <div className="w-full">
                                    <button
                                        onClick={(e) => changePass(e)}
                                        className={`${theme ? "text-S2" : "text-S1"} ${theme ? "bg-B2" : "bg-B1"} w-full font-semibold  border ${theme ? "border-S2" : "border-S1"} py-2 rounded-2xl`}>
                                    {lang === "ru" ? "ВВОД" : "SUBMIT"}</button>
                                    {errorFetchPass !== null && <Response error={errorFetchPass} />}
                                    {errorValidate !== null && <div className='my-1 text-AD text-center'>{errorValidate}</div>}
                                </div>

                            </div>
                        </div>
                        <div className="mt-10">{lang === 'ru' ? 'Нажмите "ДОБАВИТЬ БАЛАНС" для получения реквизитов.' : 'Click "ADD BALANCE" to get render an account for payment.'}</div>
                        {!viewPayBox && <Link onClick={() => console.log("CREATE PAY ", $payTags.id)} ref={linkRef} to={`/?page=pay&payment_id=${$payTags.id}`} />}
                        <button
                            onClick={() => {
                                if ($payTags.id !== null) {
                                    linkRef.current.click()
                                } else {
                                    setViewPayBox(true)
                                }
                            }}
                            className={`${theme ? "text-S2" : "text-S1"} mt-10 font-semibold  border-2 ${theme ? "border-S2" : "border-S1"} w-full p-2 ${theme ? "bg-white" : "bg-B1"} rounded-2xl`}>{lang === 'ru' ? "ДОБАВТЬ БАЛАНС" : "ADD BALANCE"}</button>
                    </div>
                    :
                    <div className="flex w-[320px]">
                        <div className="flex flex-col pt-5 px-5 gap-3 w-full">
                            <div className={`flex justify-between text-xl ${theme ? "text-S2" : "text-S1"}`}><div>{lang === "ru" ? "Баланс" : "Balance"}</div><div>{Account && Account.Balance}$</div></div>
                            <input
                                required
                                placeholder="min 100" className={`py-2 px-3 my-2 outline-none rounded-xl border ${theme ? "border-S2" : "border-S1"} ${theme ? "text-S2" : "text-S1"} ${theme ? "bg-white" : "bg-B1"} ${theme ? "shadow-xl" : ""}`} type="text" onChange={(e) => setPay(e.target.value)} value={pay} />
                            <button
                                onClick={() => {
                                    createPay(pay);
                                }}
                                className={`${theme ? "text-S2" : "text-S1"} mt-1 font-semibold  border-2 ${theme ? "border-S2" : "border-S1"} w-full p-2 ${theme ? "bg-white" : "bg-B1"} rounded-2xl`}>{viewPayBox && <Link onClick={() => console.log("CREATE PAY ", $payTags.id)} ref={linkRef} to={`/?page=pay&payment_id=${$payTags.id}`} />}{lang === 'ru' ? "ПОПОЛНИТЬ" : "PAY"}</button>
                            <button
                                onClick={() => setViewPayBox(false)}
                                className={`${theme ? "text-S2" : "text-S1"} mt-1 w-full font-semibold  border-2 ${theme ? "border-S2" : "border-S1"}  p-2 ${theme ? "bg-white" : "bg-B1"} rounded-2xl`}>{lang === 'ru' ? "АККАУНТ" : "OPEN ACCOUNT"}</button>
                            <div>
                            </div>
                        </div>

                    </div>
                }

                {viewPayBox && <button
                    onClick={() => setBuyAlert(true)}
                    className={`flex justify-center mx-auto px-7   m-5 ${theme ? "text-S2" : "text-S1"} mt-1  font-semibold  border-2 ${theme ? "border-S2" : "border-S1"}  p-2 ${theme ? "bg-white" : "bg-B1"} rounded-2xl`}>{lang === 'ru' ? "КУПИТЬ ПОДПИСКУ (3000$)" : "BUY SUBSCRIPTION (3000$)"}</button>
                }
            </div>

        </div>
    </Container>
}