import React, { useState, useRef, useEffect } from 'react';
import { RodmacerSVG } from "../../components/SVG/logoSVG";
import { ShowPassSVG } from "../../components/SVG/showPassSVG";
import { useLocalStore } from "../../store/localState";
import { useForm } from 'react-hook-form';
import { Container } from '../../components/Page';


export const Authenticate = () => {
    const [state, setState] = useState(false)
    const [showPass, setShowPass] = useState(false);
    const [success, setSuccess] = useState(false);
    const [r, sr] = useState({ login: "", password: "", confirm: "" })
    const [error, setError] = useState({ auth: false, reg: false })
    const [validate, setValidate] = useState(false)
    const { setToken, setAuthenticate, theme } = useLocalStore(state => state)

    const [captcha, setCaptcha] = useState({
        load: false,
        image: null,
        reload: 0
    })

    const registr = useForm()

    const {
        register,
        reset,
        handleSubmit,
    } = useForm();


    async function enter(data) {
        const response = await fetch(`${process.env.REACT_APP_URL}/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json()
        })
            .then(res => {
                setToken(res.token)
                setError({ reg: false, auth: true })
                setAuthenticate(true)
                reset()
            })
            .catch(err => {
                setError({ reg: false, auth: true })
                setAuthenticate(false)
                console.error(err)
            })
    };
    async function reg(e) {
        e.preventDefault()
        if ((r.login.length < 8) || (r.password.length < 8)) return setValidate(true)
        if (r.password !== r.confirm) return setError({ reg: true, auth: false })
        const response = await fetch(`${process.env.REACT_APP_URL}/registration/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ login: r.login, password: r.password })
        }).then((res) => {
            if (res.status !== 200) {
                setError({ reg: true, auth: false })
            } else {
                setError({ reg: false, auth: false })
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                    setState(false)
                    registr.reset()
                }, 3000)
            }
        })
    }

    function handleErrorRegistration() {
        if (r.password !== r.confirm) {
            return <div className='w-[300px] text-AD text-center'>Passwords do not match.</div>
        } else {
            return <div className='w-[300px]  text-AD text-center'>Login is invalid or already taken</div>
        }
    }

    return (
        <Container styles={"bg-B1 text-white font-sans flex items-center flex-col justify-center"}>

            {success && state && <div className={`transition duration-400 font-bold ${theme ? "text-S2" : "text-S1"} text-5xl`}>
                SUCCESS
            </div>}
            {state ? <>
                <form onKeyDown={e => e.keyCode === 13 ? handleSubmit(enter) :
                    ''} className={`${success && "hidden"} flex flex-col gap-2 items-center w-full`}>
                    <div className="w-[300px]">
                        <input
                            value={r.login}
                            onChange={(e) => {
                                setError({ reg: false, auth: false })
                                sr({ ...r, login: e.target.value })
                            }}
                            className={`w-full px-4 py-2 outline-none  rounded-xl bg-grey-box`}
                            placeholder="Login"
                        />
                    </div>
                    <div className="w-[300px]">
                        <div className={`flex`}>
                            <input
                                value={r.password}
                                onChange={(e) => {
                                    setError({ reg: false, auth: false })
                                    sr({ ...r, password: e.target.value })
                                }}
                                className={`w-full px-4 py-2 outline-none  rounded-xl bg-grey-box`}
                                placeholder="Password" type={showPass ? 'text' : 'password'} />
                        </div>
                    </div>
                    <div className="w-[300px]">
                        <div className={`flex`}>
                            <input
                                value={r.confirm}
                                onChange={(e) => {
                                    setError({ reg: false, auth: false })
                                    sr({ ...r, confirm: e.target.value })
                                }}
                                className={`w-full px-4 py-2 outline-none  rounded-xl bg-grey-box`}
                                placeholder="Confirm Pass" type={showPass ? 'text' : 'password'} />
                            <div>
                                <ShowPassSVG
                                    className={`cursor-pointer bg-grey-box absolute translate-x-[-40px] translate-y-2`}
                                    showPass={showPass} onClick={() => setShowPass(!showPass)} />
                            </div>
                        </div>
                    </div>
                    {validate && <div className='w-[300px] my-1 text-AD text-center'>Login and Password must contain at least 8 characters</div>}
                    {error.reg && handleErrorRegistration()}
                    <div className='flex w-[300px]'>
                        <button
                            onClick={(e) => reg(e)}
                            className={`reg_bg w-full px-2 mt-2 text-center  text-white text-xl py-1 rounded-xl`}
                        >CREATE ACCOUNT</button>

                    </div>
                    <span className='h-9 mt-1 flex items-center text-S1'>Already have an account? {" "}<span className='text-[#2EB8C4] translate-x-2 cursor-pointer' onClick={() => setState(!state)}>{" "} Sign in</span></span>
                </form></> :
                <>
                    <div className={`${success && "hidden"} flex justify-center mb-10  text-[110px] text-bold text-center pb-10 rodmacer text-end`}>
                        <RodmacerSVG /><span className='max-[740px]:hidden'>OSEIDON</span>
                    </div>
                    <form onSubmit={handleSubmit(enter)} onKeyDown={e => e.keyCode === 13 ? handleSubmit(enter) :
                        ''} className={`flex flex-col gap-2 items-center w-full`}>
                        <div className="w-[300px]">
                            <input
                                {...register("login")}
                                className={`w-full px-4 py-2 outline-none  rounded-xl bg-grey-box`}
                                placeholder="Login"
                            />
                        </div>
                        <div className="w-[300px]">
                            <div className={`flex`}>
                                <input
                                    {...register("password")}
                                    className={`w-full px-4 py-2 outline-none  rounded-xl bg-grey-box`}
                                    placeholder="Password" type={showPass ? 'text' : 'password'} />
                                <div>
                                    <ShowPassSVG
                                        className={`cursor-pointer bg-grey-box absolute translate-x-[-40px] translate-y-2`}
                                        showPass={showPass} onClick={() => setShowPass(!showPass)} />
                                </div>
                            </div>
                        </div>
                        {error.auth && <div className='w-[300px] my-1 text-AD text-center'>Incorrect username or password.</div>}
                        <button
                            type='submit'
                            className={`login_bg w-[300px] mb-2 mt-2 text-center bg-S1 text-white  text-xl py-1 rounded-xl`}
                        >SIGN IN</button>
                        <label className='text-start text-[#2EB8C4]'>Need account? <span className='cursor-pointer  text-S1' onClick={() => setState(!state)}>Create account</span></label>
                    </form>
                </>
            }
        </Container>
    )
}