import React from "react";
import { Link } from "react-router-dom";
import { useLocalStore } from "../../store/localState";

export const RouteLink = ({ path, text, SVG, current}) => {

    const {theme} = useLocalStore(state => state)

    function fill(current) {
        if (current) {
            if (theme) {
                return "text-S2"
            } else {
                return "text-S1"
            }
        } else {
            if (theme) {
                return "text-TX2"
            } else {
                return "text-TX1"
            }
        }
    }

    return <Link to={path} className={`flex my-3 mx-6 text-nowrap items-center`}>
        <div className="mr-2">
            {SVG}
        </div>
        <span className={`${fill(current)}`}>{text}</span>
    </Link>
}