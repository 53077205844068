import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useLocalStore } from "../../store/localState";

import { Input } from "../../components/UI/input";
import { Checkbox } from "../../components/UI/checkbox";

import { Response } from "../../components/response-message/index"
import { TelegramSVG } from "../../components/SVG/telegramSVG";
import { Container } from "../../components/Page";

export const Settings = React.memo(() => {
    const { theme, lang, token, setAuthenticate } = useLocalStore(state => state);

    const [error, setError] = useState(null)

    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();

    const [settings, setSettings] = useState({
        telegram_messages: false
    })

    const getSettings = async () => {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/settings/`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        }).then((res) => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            return res.json()
        }).then(d => {
            for (const key in d) {
                setValue(key, d[key],)
            }
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getSettings()
    }, []);

    async function submit(data) {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/settings/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            if (res.status > 304) {
                setError(true)
            } else {
                setError(false)
            }
        }).catch(err => {
            console.error(err)
            setError(false)
        });
    };

    useEffect(() => {
        document.title = 'Settings'
    }, [])
    return (
        <Container>
            <form onSubmit={handleSubmit(submit)} className={`w-1/2 mx-auto ${theme ? "text-black" : "text-white"}`}>
                <div className="mt-8 flex flex-col ">
                    <label>{lang === "ru" ? "Телеграм токен" : "Telegram token"}</label>
                    <Input register={register("tg_bot_token")} />
                </div>
                <div className="flex items-start mt-10 flex-col">
                    <label>{lang === 'ru' ? "Чат ID для файлов" : "Chat id for files"}</label>
                    <Input register={register("tg_chat_id")} />
                </div>
                <div className="flex items-start mt-10 flex-col">
                    <label>{lang === 'ru' ? "Чат ID для уведомлений" : "Chat ID for notification"}</label>
                    <Input register={register("tg_chatid_notiflications")} />
                </div>
                <div className="flex items-start mt-10 flex-col">
                    <label>{lang === 'ru' ? "Уникальный Текст" : "Copyright text"}</label>
                    <Input register={register("copyright")} />
                </div>
                <span
                    onClick={() => setSettings({ telegram_messages: !settings.telegram_messages })}
                    className={`flex gap-3 text-xl text-nowrap ${theme ? "border-black" : "border-white"} border-b-2 py-2 px-4 inline-block mt-8 block cursor-pointer`}> <TelegramSVG /> {lang === 'ru' ? "Сообщения Телеграм" : "Telegram Message"}:</span>
                <div className={`${!settings["telegram_messages"] && "hidden"} flex items-start mt-10 flex-col`}>
                    <textarea
                        {...register("telegram_message")}
                        className={`mt-2 mr-2 px-4 resize-none h-[450px]  outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} ${theme ? "text-0F" : "text-white"} py-2 rounded-xl w-full`}
                        type="text" />
                </div>
                <div className="flex mt-10">
                    <Checkbox register={register("anti_duplicate")} />
                    <div
                        className={`${theme ? "text-TX2" : "text-TX1"}`}>{lang === 'ru' ? "Включить антидублирование" : "Turn on the antidubles"}</div>
                </div>
                <div className="w-full flex justify-center mt-10">
                    <button type="submit"
                        className={`rounded-2xl text-white px-4 py-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>{lang === 'ru' ? "ВВОД НАСТРОЕК" : "APPLY SETTINGS"}</button>
                </div>
                <div className={`mt-2`}>
                    <Response error={error} />
                </div>
            </form>
        </Container>
    )
})