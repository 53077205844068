import React from "react";

export const Loader = () => {

    return (
        <div className="flex justify-center w-full my-12">
            <span className="relative flex h-6 w-6 mx-4">
                <span className="animate-ping absolute inline-flex h-full w-full bg-[#000]/80 opacity-95"></span>
            </span>
            <span className="relative flex h-6 w-6 mx-4">
                <span className="animate-ping absolute inline-flex h-full w-full  bg-[#000]/80 opacity-95"></span>
            </span>
            <span className="relative flex h-6 w-6 mx-4">
                <span className="animate-ping absolute inline-flex h-full w-full  bg-[#000]/80 opacity-95"></span>
            </span>
        </div>

    )
}