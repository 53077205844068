import React from "react";
import { useLocalStore } from "../../store/localState";
import { fill } from "../../utils/fill.utils.js";

export const GoogleCookiesSVG = ({current}) => {
    const {theme} = useLocalStore(state => state);
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 16L7.27627 17.6576C7.54232 19.2539 7.67535 20.0521 8.23484 20.526C8.79433 21 9.6035 21 11.2218 21H12.7782C14.3965 21 15.2057 21 15.7652 20.526C16.3247 20.0521 16.4577 19.2539 16.7237 17.6576L17 16" stroke={fill(current, theme)} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 4.5C14 3.5 15 3 16 3" stroke={fill(current, theme)} strokeWidth="2" strokeLinecap="round" />
        <circle cx="12" cy="6" r="2" stroke={fill(current, theme)} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 7.53503C9.26376 7.96082 8.67546 8.6147 8.33205 9.40116C8.17639 9.75764 7.82814 9.99151 7.43926 10.0007C6.08688 10.0327 5 11.1396 5 12.5C5 13.8807 6.11929 15 7.5 15C8.04262 15 8.54217 14.8283 8.95113 14.5361C9.26819 14.3095 9.68796 14.2878 10.0267 14.4804C10.6081 14.8109 11.2804 15 12 15C12.7196 15 13.3919 14.8109 13.9733 14.4804C14.312 14.2878 14.7318 14.3095 15.0489 14.5361C15.4578 14.8283 15.9574 15 16.5 15C17.8807 15 19 13.8807 19 12.5C19 11.1396 17.9131 10.0327 16.5607 10.0007C16.1719 9.99151 15.8236 9.75764 15.668 9.40116C15.3245 8.6147 14.7362 7.96082 14 7.53503V5.34143C15.3777 5.82843 16.5238 6.80421 17.2308 8.05908C19.3687 8.40833 21 10.2635 21 12.5C21 14.9853 18.9853 17 16.5 17C15.7509 17 15.0429 16.8163 14.4205 16.4915C13.6793 16.8185 12.8599 17 12 17C11.1401 17 10.3207 16.8185 9.57953 16.4915C8.95709 16.8163 8.24912 17 7.5 17C5.01472 17 3 14.9853 3 12.5C3 10.2635 4.63129 8.40833 6.76921 8.05908C7.47619 6.80421 8.62233 5.82843 10 5.34143V7.53503Z" fill={fill(current, theme)} />
    </svg>
    )
}