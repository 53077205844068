import React from "react";
import { useLocalStore } from "../../store/localState";

export const AlertMessage = ({ msg, error }) => {

    const { lang } = useLocalStore(state => state);

    return <>
        {!error  ?
            <div class="bg-red-100 border-t-4 border-red-100 rounded-b text-teal-900 px-4 py-3 shadow-md fixed  top-[80vh] left-[calc(50%_-_134px)] " role="alert">
                <div role="alert">
                    <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                        {lang === 'ru' ? "Ошбика" : "Error"}
                    </div>
                    <div class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                        <p>{lang === "ru" ? "Ошбика на севрере, попробуйте повторить позже." : "Server error, please try later"} </p>
                    </div>
                </div>
            </div>
            :
            <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md fixed  top-[80vh] left-[calc(50%_-_202px)] " role="alert">
                <div class="flex">
                    <div class="py-1">
                        <svg class="fill-current h-6 w-6 text-teal-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
                    </div>
                    <div>
                        <p class="font-bold mt-1">{msg}</p>
                        <p class="text-sm"></p>
                    </div>
                </div>
            </div>
        }

    </>


}