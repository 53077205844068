import React, { useMemo, useCallback } from "react";
import { Home } from "./pages/Home";
import { Logs } from "./pages/Logs";
import { GoogleCookies } from './pages/GoogleCookies';
import { Settings } from "./pages/Settings";
import { Builder } from "./pages/Builder";
import { Admin } from "./pages/Admin";
import { Authenticate } from "./pages/Authenticate";
import { useLocation } from "react-router";
import { useLocalStore } from "./store/localState";
import { ConverterCookies } from "./pages/ConverterCookies";
import { useModalStore } from "./store/modalState";
import { Account } from "./pages/Account";
import { Data } from "./pages/Data";
import { Pay } from "./pages/Account/pay";

export const PageRender = () => {
    const location = useLocation();
    const { $page, setPage, $menu, handleMenu, setCurrentPage } = useModalStore(state => state)
    const { authenticate } = useLocalStore(state => state)

    useMemo(() => {
        console.log(location.search.split("&")[0])
        if (authenticate && (location.pathname + location.search) === "/?page=token") {
            setCurrentPage("token")
            return setPage(<GoogleCookies />)
        } else if (authenticate && (location.pathname + location.search) === "/?page=converter") {
            setCurrentPage("converter")
            return setPage(<ConverterCookies />)
        } else if (authenticate && (location.pathname + location.search) === '/?page=settings') {
            setCurrentPage("settings")
            return setPage(<Settings />)
        } else if (authenticate && (location.pathname + location.search) === '/?page=builder') {
            setCurrentPage("builder")
            return setPage(<Builder />)
        } else if (authenticate && (location.pathname + location.search) === "/?page=account") {
            setCurrentPage("account")
            return setPage(<Account />)
        } else if (authenticate && (location.pathname + location.search) === "/?page=logs") {
            setCurrentPage("logs")
            return setPage(<Logs />)
        } else if (authenticate && (location.pathname + location.search) === "/?page=admin") {
            setCurrentPage("admin")
            return setPage(<Admin />)
        } else if (authenticate && (location.pathname + location.search) === "/?page=data") {
            setCurrentPage("data")
            return setPage(<Data />)
        } else if (authenticate && (location.search.split("&")[0] == "?page=pay")) {
            return setPage(<Pay />)
        } else if (authenticate) {
            setCurrentPage("home")
            return setPage(<Home />)
        } else if (!authenticate && (location.pathname + location.search) === "/?page=login") {
            setCurrentPage("login")
            setPage(<Authenticate />)
        } else if (!authenticate) {
            window.location = "/?page=login"
        }
    }, [window.location.search, authenticate])

    const handler = useCallback((e) => {
        if (!$menu) return
        if (e.target.classList !== 'menu') handleMenu()
    }, [$menu])

    return (
        <div
            onClick={handler}>
            {$page}
        </div>
    )
}