import React from "react";
import { Menu } from "./Menu";
import { MenuSetting } from "./MenuSetting";
import {useLocalStore} from "../../store/localState";

export const MenuNavigation = React.memo(() => {
    const {theme} = useLocalStore((state) => state);

    return (
        <div className={`flex justify-between  w-full fixed z-20  ${theme ? 'bg-B22' : 'bg-B1'}`}>
            <Menu />
            <MenuSetting />
        </div>
    )
})