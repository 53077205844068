import React, { useEffect, useState } from "react";

import { useLocalStore } from "../../store/localState";

import { useForm } from "react-hook-form";
import { Container } from "../../components/Page";

export const GoogleCookies = React.memo(() => {

    const { theme, lang, setAuthenticate, token } = useLocalStore((state) => state);
    const [error, setError] = useState(null);
    const {
        register,
        handleSubmit,
    } = useForm();

    const [cookies, setCookies] = useState("");
    const [copy, setCopy] = useState("");

    async function submit(data) {
        const response = await fetch('/authed/token/', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 403) {
                setAuthenticate(false);
            }
            if (res.status > 306) {
                setError(true)
            }
            return res.json()
        }).then(d => {
            setCookies(d.Cookies)
            setError(false)
        }).catch(err => {
            console.error(err)
            setError(true)
        })
    }

    useEffect(() => {
        document.title = 'Google Cookies';
    }, [])
    return (
        <Container styles={"flex items-center w-full"}>
            <div className={`w-1/2 mx-auto ${theme ? "text-black" : "text-white"}`}>
                <form onSubmit={handleSubmit(submit)}>
                    <label className="block mb-2 text-lg">{lang === 'ru' ? "Токен" : "Insert token"}</label>
                    <div className="flex justify-between mx-auto my-2">
                        <input
                            name="token"
                            placeholder={`1//dfsjkhsdfhsdfhdsfsdfdsf`}
                            {...register("token")}
                            className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-md" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-s-2xl`}
                        />
                        <button
                            type="submit"
                            className={`px-6 rounded-r-3xl text-white py-2  ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>{lang === 'ru' ? "ВВОД" : "GET"}</button>
                    </div>
                    <label className="block mb-2 mt-6 text-lg">{lang === 'ru' ? "HTTP Прокси" : "Proxy(HTTP)"}</label>
                    <div className="my-2 w-full">
                        <input
                            name='proxy'
                            {...register("proxy")}
                            placeholder={"http://username:password@proxy.example.com:808"}
                            className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-md" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
                        />
                    </div>
                </form>
                <div className="mt-10">
                    <label className=" block mb-2 mt-6 text-lg">{lang === 'ru' ? "Cookies" : "Get a Cookies"}</label>
                    <div className={`flex justify-between`}>
                        <input
                            value={cookies}
                            onChange={(e) => console.error("ERROR CHANGE")}
                            className={` w-5/6 px-4 outline-none ${theme ? "shadow-md" : ""} ${theme ? "" : "bg-grey-box"} ${theme ? "text-0F" : "text-white"} py-2 rounded-s-2xl w-full`}
                            type="text" />
                        <button
                            onClick={(e) => {
                                navigator.clipboard.writeText(cookies);
                                setCopy(true)
                            }}
                            className={`flex justify-center items-center px-4 rounded-r-2xl text-white py-2 ${theme ? "shadow-md" : ""} ${theme ? "bg-S2" : "bg-S1"} font-semibold`}>
                            {copy ?
                                <span id="success-message" className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                            strokeWidth="3"
                                            d="M1 5.917 5.724 10.5 15 1.5" />
                                    </svg>
                                    <span
                                        className="text-xs font-semibold">{lang === 'ru' ? "СКОПИРОВАНО" : "COPIED"}</span>
                                </span>
                                :
                                <span id="default-message" className="inline-flex items-center">
                                    <svg className="w-3 h-3 me-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 18 20">
                                        <path
                                            d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                    </svg>
                                    <span className="text-xs font-semibold">{lang === 'ru' ? "КОПИРОВАТЬ" : "COPY"}</span>
                                </span>
                            }

                        </button>

                    </div>
                </div>
            </div>
        </Container>
    )
})