import React from 'react';

export const CalendarSVG = ({ theme }) => {

    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2.5" y="5" width="15" height="12.5" rx="2" stroke={theme ? "#323A40" : "white"} strokeWidth="2"/>
        <path d="M2.5 8.33333C2.5 7.08718 2.5 6.4641 2.76795 6C2.94349 5.69596 3.19596 5.44349 3.5 5.26795C3.9641 5 4.58718 5 5.83333 5H14.1667C15.4128 5 16.0359 5 16.5 5.26795C16.804 5.44349 17.0565 5.69596 17.2321 6C17.5 6.4641 17.5 7.08718 17.5 8.33333H2.5Z" fill={theme ? "#323A40" : "white"}/>
        <path d="M5.8335 2.5L5.8335 5" stroke={theme ? "#323A40" : "white"} strokeWidth="2" strokeLinecap="round"/>
        <path d="M14.1665 2.5L14.1665 5" stroke={theme ? "#323A40" : "white"} strokeWidth="2" strokeLinecap="round"/>
        <rect x="5.8335" y="10" width="3.33333" height="1.66667" rx="0.5" fill={theme ? "#323A40" : "white"}/>
        <rect x="5.8335" y="13.3335" width="3.33333" height="1.66667" rx="0.5" fill={theme ? "#323A40" : "white"}/>
        <rect x="10.8335" y="10" width="3.33333" height="1.66667" rx="0.5" fill={theme ? "#323A40" : "white"}/>
        <rect x="10.8335" y="13.3335" width="3.33333" height="1.66667" rx="0.5" fill={theme ? "#323A40" : "white"}/>
    </svg>
}