import React, { useEffect, useRef, useState } from "react";
import { useLocalStore } from "../../store/localState";
import * as d3 from 'd3';
import { d3StatDayBar, d3StatWeekBar } from "../../utils/d3.utils.js"
import { d3StatStatusPieBar } from "../../utils/d3.utils.js";
import { Container } from "../../components/Page/index.jsx";
export const Data = () => {

    const { theme, token } = useLocalStore((state) => state);
    const [state, setState] = useState(null);

    const ref = useRef();

    async function get(d) {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/data/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ dto: d })
        }).then(res => res.json()).then((d) => {
            setState(d);
        })
    }

    useEffect(() => {
        document.title = "Data";
    }, []);


    return (
        <>

            <div className="tooltip w-32 h-14 bg-white rounded-2xl absolute top-0 left-0 hidden flex justify-center items-center"></div>
            <Container>
                <select onChange={(e) => {
                    document.querySelector(".p").innerHTML = ""
                    if (e.target.value === "DAY") {
                        d3StatDayBar(state, ref, theme)
                        get("DAY");
                    } else if (e.target.value === "WEEK") {
                        d3StatWeekBar(state, ref, theme);
                        get("WEEK");
                    } else if (e.target.value === "MONTH") {

                    }
                    d3StatStatusPieBar(state, theme, "total")
                }}>
                    <option value="DAY" >For Day</option>
                    <option value="WEEK" selected>For Week</option>
                    <option value="MONTH">For Month</option>
                </select>
                <div className="flex justify-around flex-wrap mt-10 gap-10">
                    <svg className="p overflow-visible" ref={ref}></svg>
                    <div className="w-[360px] h-[360px]">
                        <svg className="overflow-visible" id="my_dataviz"></svg>
                    </div>
                </div>
            </Container>
        </>

    )

}