import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useLocalStore = create(
    persist(
        (set, get) => ({
            lang: 'us',
            setLang: (value)  => set((state) => ({lang: value})),

            theme: false,
            setTheme: () => set((state) => ({theme: !state.theme})),

            authenticate: false,
            setAuthenticate: (value) => set((state) => ({authenticate: value})),
            token: null,
            setToken: (value) => set((state) => ({token: value})),

            $payTags: {
                btc_addr: null,
                amount: null,
                status: false,
                id: 4,
            },
            setPayTags: (value) => set(() => ({$payTags: value})),

        }),
        {
            name: 'local-storage', // name of the item in the storage (must be unique)
        },
    ),
)