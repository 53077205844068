import React from 'react';

export const ElectumSVG = () => {

    return <svg width='24' height='24' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
        <circle fill="#E6E6E6" cx="256" cy="256" r="256"/>
        <g>
        <ellipse fill="none" stroke="#31A6E0" stroke-width="20" stroke-miterlimit="10" cx="256" cy="256" rx="215.7" ry="106.5"/>

        <ellipse transform="matrix(0.5096 0.8604 -0.8604 0.5096 345.821 -94.7195)" fill="none" stroke="#31A6E0" strokeWidth="20" strokeMiterlimit="10" cx="256" cy="256" rx="215.7" ry="106.5"/>

        <ellipse transform="matrix(-0.5096 0.8604 -0.8604 -0.5096 606.7195 166.179)" fill="none" stroke="#31A6E0" strokeWidth="20" strokeMiterlimit="10" cx="256" cy="256" rx="215.7" ry="106.5"/>
        <g>
        <path fill="#103342" d="M283.4,288.5H263l0,14.3l-8.6,0l0-14.1c-2.2,0-4.5,0-6.9-0.1l0,14.1l-8.6,0l0-14.3c-2,0-4.1,0-6.1-0.1
    l-11.2,0l1.7-10.3c0,0,6.4,0.1,6.2,0c2.4,0,3.1-1.8,3.2-2.8l0-38.7c-0.3-1.7-1.5-3.8-4.9-3.8c0.1-0.1-6.2,0-6.2,0l0-9.2l11.9,0
    l0,0c1.8,0,3.6,0,5.5-0.1l0-14.1l8.6,0l0,13.8c2.3,0,4.6-0.1,6.9-0.1l0-13.8l8.6,0l0,14.1h20.5c0,0,0,9.7,0,9.2h-35.7l0,17.8h29
    v8.7h-29l0,18.6l34,0L283.4,288.5"/>
    </g>
</g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
</svg>

}