export function fill(current, theme) {
    if (current) {
        if (theme) {
            return "#475569"
        } else {
            return "#29E7A8"
        }
    } else {
        if (theme) {
            return "#475569"
        } else {
            return "white"
        }
    }
}