import * as d3 from 'd3';
import { debounce } from './debounce.utils';

export function d3StatWeekBar(state, ref, theme) {
    console.log("WEEK FUNC");
    const values = Object.values(state.statistics.data);
    const max = Math.max(...values.map(v => +v.total));
    let degValues = values.map(val => Math.ceil((+val.total * 100) / +max));
    const v = values.map(val => val.total);

    const w = 700;
    const h = 300;
    const marginLeft = 40;

    let svg = d3.select(ref.current)
        .attr("width", w)
        .attr("height", h)
        .style("background", "none")

    const xScale = d3.scaleLinear()
        .domain([0, values.length - 1])
        .range([0, w])

    const yScale = d3.scaleLinear()
        .domain([0, max])
        .range([h, 0])


    const xAxis = d3.axisBottom(xScale)
        .ticks(7)
        .tickFormat(i => i + 1)


    svg.append("g")
        .attr("fill", theme ? "#374151" : "#29E7A8")
        .selectAll()

        .data(degValues)
        .join("rect")
        .attr("x", (d, i) => i * 10)
        .attr("y", d => h - d)
        .attr("height", d => d)
        .attr("class", "state")
        .attr("width", 40)
        .attr('data-idx', (d, i) => i)
        .attr("transform", (d, i) => `translate(${(i * 2.65 * marginLeft) - 20},0)`)

    svg.append("g")
        .call(xAxis)
        .attr("transform", `translate(0, ${h - 3})`)
        .attr('stroke', theme ? "#374151" : "#29E7A8")

    const toolpit = document.querySelector(".tooltip")
    const tick = document.querySelectorAll(".tick");
    const days = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВC"]

    for (let i = 0; i < days.length; i++) {
        tick[i].querySelector("text").innerHTML = days[i]
    }

    document.querySelectorAll(".state").forEach((el) => {
        const idx = el.dataset.idx
        const datEl = state.statistics.data[idx]

        el.addEventListener("mouseover", debounce((e) => {
            toolpit.classList.remove("hidden")
            toolpit.style.left = e.pageX + "px"
            toolpit.style.top = e.pageY - 50 + "px"
            toolpit.innerHTML = `
            LOGS: ${datEl.total}
        `
            d3StatStatusPieBar(datEl, theme, 'week')
        }), 50)
        el.addEventListener("mouseleave", debounce((e) => {
            toolpit.classList.add("hidden");
            d3StatStatusPieBar(state, theme, 'total')
        }), 50)
    })

    document.querySelector(".domain").style.transform = `scale(1.052) translateX(-19px)`;
    document.querySelectorAll(".domain").forEach(el => el.style.fill = theme ? "#374151" : "#29E7A8");
}

export function d3StatDayBar(state, ref, theme) {
    console.log("DAY FUNC");

    const values = Object.values(state.statistics.data);
    const max = Math.max(...values.map(v => +v.total));
    let degValues = values.map(val => Math.ceil((+val.total * 100) / +max));
    const v = values.map(val => val.total);
    console.log(v, degValues, values)

    const w = 700;
    const h = 300;
    const marginLeft = 40;

    let svg = d3.select(ref.current)
        .attr("width", w)
        .attr("height", h)
        .style("background", "none")

    const xScale = d3.scaleLinear()
        .domain([0, values.length - 1])
        .range([0, w])

    const yScale = d3.scaleLinear()
        .domain([0, max])
        .range([h, 0])


    const xAxis = d3.axisBottom(xScale)
        .ticks(7)
        .tickFormat(i => i + 1)

    svg.append("g")
        .attr("fill", theme ? "#374151" : "#29E7A8")
        .selectAll()

        .data(degValues)
        .join("rect")
        .attr("x", (d, i) => i * 10)
        .attr("y", d => h - d)
        .attr("height", d => d)
        .attr("class", "state")
        .attr("width", 40)
        .attr('data-idx', (d, i) => i)
        .attr("transform", (d, i) => `translate(${(i * 2.65 * marginLeft) - 20},0)`)

    svg.append("g")
        .call(xAxis)
        .attr("transform", `translate(0, ${h - 3})`)
        .attr('stroke', theme ? "#374151" : "#29E7A8")

    const toolpit = document.querySelector(".tooltip")
    const tick = document.querySelectorAll(".tick");
    const days = ["00:00", "02:00", "04:00", "06:00",
                "08:00", "10:00", "12:00", "14:00",
                 "16:00", "18:00", "20:00", "22:00"]

    for (let i = 0; i < days.length; i++) {
        tick[i].querySelector("text").innerHTML = days[i]
    }

    document.querySelectorAll(".state").forEach((el) => {
        const idx = el.dataset.idx
        const datEl = state.statistics.data[idx]

        el.addEventListener("mouseover", (e) => {
            toolpit.classList.remove("hidden");
            toolpit.style.left = e.pageX + "px"
            toolpit.style.top = e.pageY - 50 + "px"
            toolpit.innerHTML = `
            LOGS: ${datEl.total}
        `
            d3StatStatusPieBar(datEl, theme, 'day')
        })
        el.addEventListener("mouseleave", (e) => {
            toolpit.classList.add("hidden");
            d3StatStatusPieBar(state, theme, 'total')
        })
    })

    document.querySelector(".domain").style.transform = `scale(1.052) translateX(-19px)`;
    document.querySelectorAll(".domain").forEach(el => el.style.fill = theme ? "#374151" : "#29E7A8");
}




export function d3StatStatusPieBar(state, theme, mode) {
    const statusData = { OPEN: 0, NEW: 0 }

    if (mode === "total") {
        for (const key of Object.entries(state.statistics.data)) {
            statusData.OPEN += +key[1].open
            statusData.NEW += +key[1].new
        }
    } else {
        statusData.OPEN = +state.open
        statusData.NEW = +state.new
    }
    console.log(statusData, state)

    const width = 360,
        height = 360,
        margin = 40;

    const radius = Math.min(width, height) / 2 - margin

    const svg = d3.select("#my_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`)
        .text(d => d)


    const color = d3.scaleOrdinal()
        .range(d3.schemeSet2);

    const pie = d3.pie()
        .value(function (d) { return d[1] })
    const data_ready = pie(Object.entries(statusData))

    const arcGenerator = d3.arc()
        .innerRadius(0)
        .outerRadius(radius)

    console.log(data_ready)

    svg
        .selectAll('mySlices')
        .data(data_ready)
        .join('path')
        .attr('d', arcGenerator)
        .attr('fill', function (d) { return d.data[0] === "OPEN" ? "#AD3961" : theme ? "#374151" : "#29E7A8" })
        .attr("stroke", "black")
        .style("stroke-width", "2px")

    svg
        .selectAll('mySlices')
        .data(data_ready)
        .join('text')
        .text(function (d) { return d.data[0] + " " + d.data[1] })
        .attr("transform", function (d) { return `translate(${arcGenerator.centroid(d)})` })
        .style("text-anchor", "middle")
        .style("font-size", 17)
}