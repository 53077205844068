import React, { useEffect, useState } from "react";
import { Container } from "../../components/Page";
import { QRCodeSVG } from "qrcode.react";
import { CopySVG } from "../../components/UI/copy";
import { BitcoinSVG } from "../../components/SVG/bitcoinSVG";
import { useLocation } from "react-router";
import P from "./P.svg"
import { useLocalStore } from "../../store/localState";
import { useLogerStore } from "../../store/logerState";

export const Pay = () => {
    const [copy, setCopy] = useState({ btc_addr: false, amount: false });
    const [success, setSuccess] = useState(false)
    const { lang, theme, token, $payTags, setPayTags } = useLocalStore(state => state)
    const [warning, setWarning] = useState(false)

    async function getPay() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/pay/get_payment/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ ID: $payTags.id })
        }).then((res) => {
            return res.json()
        }).then(d => {
            setPayTags(d)
        })
    };


    // useEffect(() => {
    //     if ($payTags.id !== null) {
    //         getPay()
    //     }
    // }, [])

    function handleSumCopy() {
        navigator.clipboard.writeText($payTags.amount);
        setCopy({ btc_addr: false, amount: true });
    };

    function handleAddressCopy() {
        navigator.clipboard.writeText($payTags.btc_addr);
        setCopy({ btc_addr: true, amount: false });
    };

    async function checkPay() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/pay/check_pay/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ ID: $payTags.id })
        }).then((res) => {
            return res.json()
        }).then(d => {
            if (d.Status) {
                setSuccess(true)
                setPayTags({
                    btc_addr: null,
                    amount: null,
                    status: false,
                    id: null
                })
                setTimeout(() => {
                    setSuccess(false)
                    return window.location.reload()
                }, 2000)
            }
        })
    };

    async function deletePay() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/pay/delete_pay/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
            body: JSON.stringify({ ID: $payTags.id })
        }).then((res) => {
            if (res.status < 304) {
                setPayTags({
                    btc_addr: null,
                    amount: null,
                    status: false,
                    id: null
                })
                setWarning(false);
                return window.location.replace("/?page=account");
            }
        })
    }

    return <Container styles={"flex justify-center items-center"}>
        {warning &&
            <div
                className={`${theme ? "bg-[#000]/20" : "bg-[#000]/60"} mt-15 flex justify-center items-center w-screen h-screen  z-10 top-0 right-0 fixed`}>
                <div
                    className={`w-86 ${theme ? "bg-B2" : "bg-B1"} px-10 py-5 rounded-2xl relative overflow-clip flex flex-col justify-between`}>
                    <span className={`text-red-800 flex items-center`}>
                        <svg fill="currentColor" className="flex-shrink-0 inline w-4 h-4 me-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        CONFIRM CANCEL PAYMENT?
                    </span>
                    <div
                        className={`flex gap-10 mt-4 justify-center items-center ${theme ? "text-TX2" : ""}`}>
                        <button
                            onClick={() => deletePay()}
                            className={`px-4 py-2 border-2 text-red-800 border-red-800`}>
                            YES
                        </button>
                        <button
                            onClick={(e) => setWarning(false)}
                            className={`px-4 py-2 border-2 ${theme ? "text-S2" : "text-S1"} ${theme ? "border-S2" : "border-S1"}`}>
                            NO
                        </button>
                    </div>
                </div>
            </div>
        }

        <div className={`max-w-[600px]  w-full border-2 ${success ? "border-none" : theme ? "border-S2" : "border-S1"} mx-auto rounded-lg px-10`}>

            {success ? <div className={`${theme ? "text-S2" : "text-S1"} animate-ping flex justify-center items-center text-6xl font-black h-[408px]`}>SUCCESS</div>
                : <>

                    <div className="p-5 flex justify-between gap-5">
                        <div>
                            <QRCodeSVG size={200} bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false} imageSettings={{
                                    src: P,
                                    height: 36,
                                    width: 36,
                                    excavate: true,
                                }} value={`bitcoin:${$payTags.btc_addr}?amount=${$payTags.amount}`} />
                        </div>

                        <div className={`inline-block ${theme ? "text-S2" : "text-S1"} text-xl px-2 break-all`}>
                            <div className="flexwwx flex-col">
                                <div className="flex">{$payTags.amount} <BitcoinSVG current={true} />  <CopySVG onClick={() => handleSumCopy()} copy={copy.amount} /></div>
                                <div className="flex my-2">{$payTags.btc_addr} <CopySVG onClick={() => handleAddressCopy()} copy={copy.btc_addr} /></div>
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={() => checkPay()}
                        className={`${theme ? "text-S2" : "text-S1"} mt-1 w-full font-semibold  border-2 ${theme ? "border-S2" : "border-S1"}  p-2 ${theme ? "bg-transparent" : "bg-B1"} rounded-2xl`}>{lang === 'ru' ? "ПРОВЕРИТЬ ОПЛАТУ" : "CHECK PAY"}</button>
                    <button
                        onClick={() => setWarning(true)}
                        className={`mt-5 ${theme ? "text-AD" : "text-AD"} mt-1 w-full font-semibold  border-2 ${theme ? "border-AD" : "border-AD"}  p-2 ${theme ? "bg-transparent" : "bg-B1"} rounded-2xl`}>{lang === 'ru' ? "ОТМЕНИТЬ ОПЛАТУ" : "CLOSE PAY"}</button>

                    <div className={`${theme ? "text-S2" : "text-S1"} text-center mt-5 mb-2`}>{lang === "ru" ? "Не закрывайте окно пока не завершится транзакция!" : "Do not close this page until payment is success!"}</div>

                </>
            }

        </div>



    </Container>
}