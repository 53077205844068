import React from 'react';

export const ExodusSVG = () => {


	return <svg width="24" height="24" viewBox="0 0 227 230" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M226.623 64.2194L128.678 0V35.9054L191.51 76.735L184.118 100.125H128.678V129.875H184.118L191.51 153.265L128.678 194.095V230L226.623 165.986L210.607 115.103L226.623 64.2194Z"
			fill="url(#paint0_linear_68_536)"/>
		<path
			d="M43.464 129.875H98.6991V100.125H43.2587L36.072 76.735L98.6991 35.9054V0L0.754395 64.2194L16.7705 115.103L0.754395 165.986L98.9044 230V194.095L36.072 153.265L43.464 129.875Z"
			fill="url(#paint1_linear_68_536)"/>
		<mask id="mask0_68_536"  maskUnits="userSpaceOnUse" x="0" y="0" width="227" height="230">
			<path
				d="M226.623 64.2194L128.678 0V35.9054L191.511 76.735L184.119 100.125H128.678V129.875H184.119L191.511 153.265L128.678 194.095V230L226.623 165.986L210.607 115.103L226.623 64.2194Z"
				fill="url(#paint2_linear_68_536)"/>
			<path
				d="M43.4643 129.875H98.6993V100.125H43.2589L36.0722 76.735L98.6993 35.9054V0L0.754639 64.2194L16.7708 115.103L0.754639 165.986L98.9047 230V194.095L36.0722 153.265L43.4643 129.875Z"
				fill="url(#paint3_linear_68_536)"/>
		</mask>
		<g mask="url(#mask0_68_536)">
			<rect x="0.875488" width="224.25" height="230" fill="url(#paint4_linear_68_536)"/>
		</g>
		<defs>
			<linearGradient id="paint0_linear_68_536" x1="194.938" y1="245.813" x2="129.33" y2="-25.2585"
							gradientUnits="userSpaceOnUse">
				<stop stop-color="#0B46F9"/>
				<stop offset="1" stop-color="#BBFBE0"/>
			</linearGradient>
			<linearGradient id="paint1_linear_68_536" x1="194.938" y1="245.813" x2="129.33" y2="-25.2585"
							gradientUnits="userSpaceOnUse">
				<stop stop-color="#0B46F9"/>
				<stop offset="1" stop-color="#BBFBE0"/>
			</linearGradient>
			<linearGradient id="paint2_linear_68_536" x1="194.938" y1="245.813" x2="129.33" y2="-25.2585"
							gradientUnits="userSpaceOnUse">
				<stop stop-color="#0B46F9"/>
				<stop offset="1" stop-color="#BBFBE0"/>
			</linearGradient>
			<linearGradient id="paint3_linear_68_536" x1="194.938" y1="245.813" x2="129.33" y2="-25.2585"
							gradientUnits="userSpaceOnUse">
				<stop stop-color="#0B46F9"/>
				<stop offset="1" stop-color="#BBFBE0"/>
			</linearGradient>
			<linearGradient id="paint4_linear_68_536" x1="15.2505" y1="51.75" x2="128.813" y2="136.562"
							gradientUnits="userSpaceOnUse">
				<stop offset="0.119792" stop-color="#8952FF" stop-opacity="0.87"/>
				<stop offset="1" stop-color="#DABDFF" stop-opacity="0"/>
			</linearGradient>
		</defs>
	</svg>


}