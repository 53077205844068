import { create } from "zustand";
import {Authenticate} from "../pages/Authenticate";

export const useModalStore = create((set) => {

    return {
        $currentPage: "",
        setCurrentPage: (value) => set(() => ({$currentPage: value})),
         
        $page: <Authenticate/>,
        setPage: (value) => set(() => ({$page: value })),

        $search: false,
        handleSearch: () => set((state) => ({$search: !state.$search})),

        $fastSearch: false,
        handleFastSearch: ()  => set((state) => ({$fastSearch: !state.$fastSearch})),

        $menu: false,
        handleMenu: () => set((state) => ({$menu: !state.$menu})),

        $adminAdd: false,
        $adminUpdate: false,
        $adminWarning: false,
        $current: -1,
        handleAdminUser: (value, id) => {
            if ((value === "edit") || (value === "delete")) {
                set(() => ({$current: id}));
            }
            set((state) => value === 'add' ? ({$adminAdd: !state.$adminAdd}) : value === 'delete' ? ({$adminWarning: !state.$adminWarning}) : ({$adminUpdate: !state.$adminUpdate}))

        },

        archives: [],
        setArchive: (value) => set(() => ({archives: value}))
    }
})

