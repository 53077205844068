import React, { useEffect, useState } from "react";
import { useLocalStore } from "../../store/localState";

import { useForm } from "react-hook-form";
import { Container } from "../../components/Page";

export const ConverterCookies = () => {
    const { theme, lang } = useLocalStore(state => state);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm()
    const [copy, setCopy] = useState(false);

    function parseFormat(c) {
        setCopy(false)
        var output = {};
        var co = c.start.toString()
        if (co == undefined) return
        co.split(/\s*;\s*/).forEach(function (pair) {
            pair = pair.split(/\s*=\s*/);
            output[pair[0]] = pair.splice(1).join('=');
        })
        var json = JSON.stringify(output, null, 4);
        return setValue("end", json, {
            shouldValidate: true,
            shouldDirty: true
        })
    }

    useEffect(() => {
        document.title = "Converter Cookies"
    }, []);
    return <Container styles={"w-full flex items-center"}>
        <form onSubmit={handleSubmit(parseFormat)} className={`w-2/3 mx-auto flex justify-between gap-10 ${theme ? "text-black" : "text-white"} max-[640px]:flex-col`}>
            <div className="w-full">
                <textarea
                    {...register("start")}
                    className={`outline-none w-full h-72 ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "shadow-md" : ""}  resize-none overflow-y-scroll p-2`}
                ></textarea>
                <button
                    type="submit"
                    className={`w-full px-2 py-1 text-sm cursor-pointer ${theme ? 'text-white' : 'text-black'} ${theme ? "bg-S2" : "bg-S1"} ${theme ? "border-S2" : "border-S1"} border-2 `}>{lang === "ru" ? "ВВОД" : "INPUT"}</button>

            </div>
            <div className="w-full">

                <textarea
                    {...register("end")}
                    onChange={() => setCopy(false)}
                    className={`outline-none w-full h-72 ${theme ? "bg-white" : "bg-grey-box"} ${theme ? "shadow-md" : ""} resize-none overflow-y-scroll p-2`}
                ></textarea>
                <div
                    onClick={() => {
                        navigator.clipboard.writeText(getValues("end"));
                        setCopy(true)
                    }}
                    className="flex justify-between">
                    <label
                        className={`px-2 py-1 text-sm ${theme ? 'text-black' : 'text-white'}  border-2 `}>{lang === 'ru' ? "ВЫВОД" : "OUTPUT"} </label>
                    <label className={`px-2 w-full text-center py-1 text-sm cursor-pointer ${theme ? 'text-white' : 'text-black'} ${theme ? "bg-S2" : "bg-S1"} ${theme ? "border-S2" : "border-S1"} border-2 `}>
                        {copy ?
                            <span id="success-message" className="inline-flex items-center">
                                <svg className="w-3 h-3 me-1.5" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                        strokeWidth="3"
                                        d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span
                                    className="text-xs font-semibold">{lang === 'ru' ? "СКОПИРОВАНО" : "COPIED"}</span>
                            </span>
                            :
                            <span id="default-message" className="inline-flex items-center">
                                <svg className="w-3 h-3 me-1.5" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" viewBox="0 0 18 20">
                                    <path
                                        d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                </svg>
                                <span className="text-xs font-semibold">{lang === 'ru' ? "КОПИРОВАТЬ" : "COPY"}</span>
                            </span>
                        }
                    </label>
                </div>
            </div>
        </form>
    </Container>
}