import React from 'react';

export const TronSVG = ({theme}) => {

    return <svg fill={theme ? 'black' : 'white'} width='24' height='24' version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 2680.2 2915.7" xmlSpace="preserve">
        <path fill={theme ? 'black' : 'white'} xmlns="http://www.w3.org/2000/svg" className="st0"
              d="M1929.1,757.7L332,463.8l840.5,2114.9l1171.1-1426.8L1929.1,757.7z M1903.4,887.2l244.3,232.2l-668.2,121  L1903.4,887.2z M1334.4,1216.2L630.1,632.1l1151.1,211.8L1334.4,1216.2z M1284.2,1319.5l-114.8,949.4L550.2,710.7L1284.2,1319.5z   M1390.5,1369.9l739.9-134l-848.7,1034L1390.5,1369.9z"/>
    </svg>

}