import React from 'react';

export const BitgetSVG = () => {

    return <svg
        height='24'
        viewBox="0 0 40 40"
        fill="none"
        version="1.1"
        id="svg2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsSvg="http://www.w3.org/2000/svg">
        <defs
            id="defs2"/>
        <rect
            x="0"
            width="40"
            height="40"
            rx="8.7109404"
            fill="#00f0ff"
            id="rect1"
            y="0"/>
        <path
            d="m 18.459617,15.7671 h 7.4686 l 7.6404,7.5916 c 0.497,0.4938 0.4996,1.2971 0.0051,1.7934 L 23.775317,35 h -7.6937 l 2.326,-2.2613 8.54,-8.4861 -8.4316,-8.4862"
            fill="#1b1b1b"
            id="path1"/>
        <path
            d="m 21.529217,24.2336 h -7.4686 l -7.64042,-7.5917 c -0.49702,-0.4938 -0.49956,-1.297 -0.00508,-1.7934 L 16.213517,5 h 7.6937 l -2.326,2.26132 -8.54,8.48608 8.4316,8.4862"
            fill="#1b1b1b"
            id="path2"/>
    </svg>

}