import React from 'react';
import { useLocalStore } from '../../store/localState';

export const Checkbox = ({handler, checked, value, register }) =>  {
    const {theme } = useLocalStore(state => state)
    return(
        <input type="checkbox"
                        {...register}
                        checked={checked}
                        onChange={handler}
                        className={`before:content[''] peer z-0 mr-2 relative h-6 w-6 cursor-pointer appearance-none rounded-lg border ${theme ? "border-S2" : "border-S1"} transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity ${theme ? 'checked:border-S2' : 'checked:border-S1'} ${theme ? 'checked:bg-S2' : 'checked:bg-S1'} ${theme ? 'checked:before:bg-S2' : 'checked:before:bg-S1'} hover:before:opacity-10"
                        id="blue`} />
    )
}