import React from 'react';

export const NoteSVG = ({theme}) => {

    return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.91669 10.6111C4.10085 10.6111 4.25523 10.5419 4.37981 10.4035C4.5044 10.265 4.56669 10.0935 4.56669 9.88889C4.56669 9.68426 4.5044 9.51273 4.37981 9.37431C4.25523 9.23588 4.10085 9.16667 3.91669 9.16667C3.73252 9.16667 3.57815 9.23588 3.45356 9.37431C3.32898 9.51273 3.26669 9.68426 3.26669 9.88889C3.26669 10.0935 3.32898 10.265 3.45356 10.4035C3.57815 10.5419 3.73252 10.6111 3.91669 10.6111ZM3.26669 7.72222H4.56669V3.38889H3.26669V7.72222ZM6.51669 10.6111H11.0667V9.16667H6.51669V10.6111ZM6.51669 7.72222H11.0667V6.27778H6.51669V7.72222ZM6.51669 4.83333H11.0667V3.38889H6.51669V4.83333ZM1.96669 13.5C1.60919 13.5 1.30315 13.3586 1.04856 13.0757C0.793979 12.7928 0.666687 12.4528 0.666687 12.0556V1.94444C0.666687 1.54722 0.793979 1.20718 1.04856 0.924306C1.30315 0.641435 1.60919 0.5 1.96669 0.5H12.3667C12.7242 0.5 13.0302 0.641435 13.2848 0.924306C13.5394 1.20718 13.6667 1.54722 13.6667 1.94444V12.0556C13.6667 12.4528 13.5394 12.7928 13.2848 13.0757C13.0302 13.3586 12.7242 13.5 12.3667 13.5H1.96669ZM1.96669 12.0556H12.3667V1.94444H1.96669V12.0556Z"
            fill={theme ? "#475569" : "white"} />
    </svg>

}