import React from 'react';
import {useLocalStore} from "../../store/localState";

export const Input = React.memo(({register, placeholder}) => {
    const theme = useLocalStore(state => state.theme)
    return <div className="my-2 w-full">
        <input
            {...register}
            placeholder={placeholder}
            className={`w-full px-4 outline-none ${theme ? "" : "bg-grey-box"} ${theme ? "shadow-md" : ""} ${theme ? "text-0F" : "text-white"} py-2 rounded-2xl`}
            type="text"/>
    </div>
})