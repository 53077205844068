import React from "react";

import {useLocalStore} from "../../store/localState";
import {useLogerStore} from "../../store/logerState";
import {LangSVG} from "../SVG/langSVG";

export const MenuSetting = React.memo(() => {
    const {statistic} = useLogerStore(state => state)
    const {theme, setTheme, lang, setLang} = useLocalStore((state) => state);

    return (
        <div className={`${theme ? "text-TX2" : "text-TX1"} ${theme ? 'bg-B22' : 'bg-B1'} mx-6 my-4 flex items-center h-8`}>
            <div className="max-[640px]:hidden mr-2">
                <LangSVG lang={lang} />
            </div>
            <div className="max-[640px]:hidden mr-6">
                <select
                    value={lang}
                    onChange={(e) => setLang(e.target.value)}
                    className={`${theme ? 'text-TX2' : 'text-TX1'} bg-transparent`}>
                    <option
                        className="bg-[#000]/40"
                        value="us">US</option>
                    <option
                        className="bg-[#000]/40"
                        value="ru">RU</option>
                </select>
            </div>
            <div className={`max-[640px]:hidden  w-12 h-6 rounded-3xl border ${theme ? "border-TX2" : "border-TX1"}  rounden-2xl mr-6`}>
                <div
                    onClick={() => setTheme()}
                    className={`${theme ? 'translate-x-6' : ''} border ${theme ? "border-TX2" : "border-TX1"} cursor-pointer rounded-full w-5 h-5 m-[1px] bg-white transition`}></div>
            </div>
            <div className="max-[640px]:hidden flex items-center mr-12">
                <svg width="45" height="46" viewBox="0 0 45 46" fill='none' xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="19.25" r="5.625" stroke={theme ? '#475569' : 'white'} strokeWidth="2" strokeLinecap="round" />
                    <circle cx="22.5" cy="23" r="16.875" stroke={theme ? '#475569' : 'white'} strokeWidth="2" />
                    <path d="M33.3385 35.7995C33.5768 35.6686 33.6896 35.3853 33.5888 35.1328C32.8658 33.3209 31.4729 31.7252 29.5902 30.5623C27.5561 29.306 25.0639 28.625 22.5 28.625C19.9361 28.625 17.4439 29.306 15.4098 30.5623C13.5271 31.7252 12.1342 33.3209 11.4112 35.1327C11.3104 35.3853 11.4232 35.6686 11.6615 35.7995C18.412 39.5056 26.588 39.5056 33.3385 35.7995Z" fill={theme ? '#475569' : 'white'} />
                </svg>
                <span
                    style={{ fontFamily: "Inter, sans-serif" }}
                    className={`${theme ? 'text-TX2' : 'text-TX1'} text-base`}>
                    {statistic.Account ? statistic.Account.Username : "unknown"}
                </span>
            </div>
        </div>
    )
})