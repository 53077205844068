import React from 'react';

export const BurgerSVG = React.memo(({$menu, handler, theme}) => {

    return <>
        {$menu
            ?
            <svg
                onClick={handler}
                className="cursor-pointer mr-10"
                width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="9" stroke={theme ? '#475569' : 'white'}
                        strokeWidth="2"/>
                <path d="M9.00012 14.9995L15.0001 8.99951" stroke={theme ? '#475569' : 'white'}
                      strokeWidth="2"/>
                <path d="M15 15L9 9" stroke={theme ? '#475569' : 'white'} strokeWidth="2"/>
            </svg>
            :
            <svg
                onClick={handler}
                className="cursor-pointer mr-10"
                width="25" height="24" viewBox="0 0 25 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 7H19.5" stroke={theme ? '#475569' : 'white'} strokeWidth="2"
                      strokeLinecap="round"/>
                <path d="M5.5 12H19.5" stroke={theme ? '#475569' : 'white'} strokeWidth="2"
                      strokeLinecap="round"/>
                <path d="M5.5 17H19.5" stroke={theme ? '#475569' : 'white'} strokeWidth="2"
                      strokeLinecap="round"/>
            </svg>
        }
    </>
})