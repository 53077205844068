import React, { useMemo } from 'react'
import { PageRender } from './render';

import { MenuNavigation } from './components/Menu/MenuNavigation';

import {useLogerStore} from "./store/logerState";
import {useLocalStore} from "./store/localState";
import { useModalStore } from './store/modalState';

function App() {
    const {setArchive, archives} = useModalStore((state) => state)
    const {authenticate, setAuthenticate, token} = useLocalStore(state => state);
    const {setStatistic} = useLogerStore((state) => state);

    async function fetchArchive() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/archives/names/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        }).then(res => res.json()).then(d => setArchive(d))
    }

    async function getStatistics() {
        const response = await fetch(`${process.env.REACT_APP_URL}/authed/total_api/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            }
        }).then(d => {
            if (d.status === 403) {
                setAuthenticate(false);
            }
            return d.json()
        }).then((res) => {
                setStatistic(res)
        }).catch(err => {
            console.error(err)
        })
    }

  useMemo(() => {
      if (!authenticate) return
      getStatistics()
      fetchArchive()
      setInterval(() => {
        fetchArchive()
      }, 30000)
  }, [authenticate]);



  return (
    <div>
        <div>{!authenticate  ? "" : <MenuNavigation />}</div>
        <PageRender />
    </div>
  );
}

export default App;
